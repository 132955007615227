import React from 'react';
import {
    Menu, MenuButton, MenuItem, MenuList, Button, Text
} from '@chakra-ui/react'
import { SettingsIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'

const QAButtons = ({ handleUpdate, QA, deleteQuestion, loading }) => {
    
    return (

        <Menu >
            <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                <SettingsIcon pb={"2px"} />
            </MenuButton>
            <MenuList  >
                <MenuItem isDisabled  >
                    <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                </MenuItem>
                <MenuItem
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems="center">
                    <Button
                        borderRadius={"5px"}
                        bg={"#f2f2f2"}
                        color={"#1c2147"}
                        _hover={{ background: "#7080fc", color: 'white' }}
                        onClick={() => handleUpdate(QA)}
                        width={"100%"}
                        justifyContent="space-between"
                        alignItems="center">
                        <Text flex={1} textAlign="center">
                            Edit
                        </Text>
                        <EditIcon ml={2} verticalAlign="middle" />
                    </Button>
                    <Button
                        borderRadius={"5px"}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        bg={'#f2f2f2'}
                        color='#1c2147'
                        _hover={{ background: "#fc7466", color: 'white' }}
                        width="100%"
                        onClick={() => deleteQuestion(QA)}
                        isLoading={loading}
                        marginTop={'5%'}
                    >
                        <Text
                            flex={1}
                            textAlign="center"
                        >
                            Delete
                        </Text>
                        <DeleteIcon ml={2} verticalAlign="middle" />
                    </Button>
                </MenuItem>
            </MenuList>
        </Menu>
    );
};
export default QAButtons;