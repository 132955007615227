import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CategoriesTable from './table';


function UseCategories() {
    const [categorie, setCategorie] = useState([]);
    const [loading, setLoading] = useState(false);
     const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('name');
    const [selectCategorie, setSelectCategorie] = useState(null);
    const [flashing, setFlashing] = useState(false);
  

     const clearFilters = () => {
        setSearchTerm('');
        setSearchType('');
        setCurrentPage(1);
    }
 
    const fetchData = async () => {
        try {
            const response = await axios.get(`/categories?page=${currentPage}&${searchType}=${searchTerm}`);
            const responseData = response.data;
            setCategorie(responseData.data);
            setTotalPages(responseData.last_page);
            setTotal(responseData.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, searchTerm, searchType,  flashing]);

     const handleSearch = () => {
        setCurrentPage(1);
    };
 
     const openModalCreate = () => {
        setModalCreateIsOpen(true);
    }

    const closeModalCreate = () => {
        setModalCreateIsOpen(false);
    }

    const handleUpdate = async (categorie) => {
        setSelectCategorie(categorie);
        setModalUpdateIsOpen(true);
    };
    const closeModalUpdate = () => {
        setModalUpdateIsOpen(false);
    }

    const getRowBackground = (categorie) => {
        if (selectCategorie && selectCategorie.id === categorie.id) {
            if (flashing) {
                return '#fcd677';
            }
            return categorie.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return categorie.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };
    return (
        <CategoriesTable
        setSearchType={setSearchType}
        openModalCreate={openModalCreate}
        searchType={searchType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch} 
        clearFilters={clearFilters} 
        categorie={categorie}
        getRowBackground={getRowBackground}
        handleUpdate={handleUpdate}
        loading={loading}
        total={total}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        modalCreateIsOpen={modalCreateIsOpen}
        closeModalCreate={closeModalCreate}
        fetchData={fetchData}
       ModalUpdateIsOpen={ModalUpdateIsOpen}
        closeModalUpdate={closeModalUpdate}
        selectCategorie={selectCategorie}
        setFlashing={setFlashing}
        />
        
    );
}
export default UseCategories;