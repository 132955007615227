import React, { useState, useEffect } from 'react';
import {
    TableContainer, Button, Flex, useToast
} from '@chakra-ui/react'
import axios from 'axios';
import QAsTable from './table';
import UpdateQAFormats from '../update';
import Swal from 'sweetalert2'

function UseQA5_1({ navigate }) {
    const [QA, setQA] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
    const [selectQA, setSelectQA] = useState(null);
    const [flashing, setFlashing] = useState(false);
    const toast = useToast();

    const fetchData = async () => {
        try {
            const response = await axios.get(`/indexQAFormats`);
            const responseData = response.data;
            setQA(responseData.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [flashing]);

    const handleUpdate = async (QA) => {
        setSelectQA(QA);
        setModalUpdateIsOpen(true);

    };
   
    const deleteQuestion = async (QA) => {
        
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this question?',
            text: "The question will be permanently removed",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            setLoading(true);
            try {
                const response = await axios.delete(`/qaFormats/delete/${QA.id}`);
                toast({
                    title: "Question Delete it!",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
                
            } catch (error) {
                console.error(error);

            } finally {
                setLoading(false);
            }
        }
    };


    const closeModalUpdate = () => {
        setModalUpdateIsOpen(false);
    }

    const getRowBackground = (QA) => {
        if (selectQA && selectQA.id === QA.id) {
            if (flashing) {
                return '#fcd677';
            }
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };

    const exportQA = () => {
        axios.get(
            `/exportQA/${null}`, {
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'QA.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error al descargar el archivo Excel:', error);
            });
    };

    
    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    mt={'1%'}
                    direction='column'
                    w='100%'
                    borderRadius="16px"
                >
                    <Flex align="center" justifyContent="flex-end" >
                        <Button class="buttonCancel" onClick={() => navigate("QALayouts")}>
                            Back
                        </Button>
                        <Button class="buttonCreateQuestion" onClick={exportQA} >
                            Export
                        </Button>
                    </Flex>
                </Flex>
                <QAsTable
                    QA={QA}
                    getRowBackground={getRowBackground}
                    loading={loading}
                    fetchData={fetchData}
                    selectQA={selectQA}
                    setFlashing={setFlashing}
                    handleUpdate={handleUpdate}
                    ModalUpdateIsOpen={ModalUpdateIsOpen}
                    closeModalUpdate={closeModalUpdate}
                    navigate={navigate}
                    deleteQuestion={deleteQuestion}
                />
            </Flex>
            {ModalUpdateIsOpen && (
                <UpdateQAFormats isOpen={ModalUpdateIsOpen} onClose={closeModalUpdate} QA={selectQA} onQAFormatsUpdated={fetchData} setFlashing={setFlashing} />)}

        </TableContainer>
    );
}
export default UseQA5_1;