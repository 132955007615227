import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseMXRU from "./items/MXRU/functions";

export default function FinalPayrollMXRUOverview() {
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card>
        <UseMXRU/>
      </Card>
    </Flex>
  );
} 