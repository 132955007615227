import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    Text, Textarea, Button, Input,
    Box, Flex,
    FormControl, FormErrorMessage,
    useToast
}
    from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import { IoMdSend } from "react-icons/io";
import { ImAttachment } from "react-icons/im";
import { IoDocument } from "react-icons/io5";

function FinancialRequest({ isOpen, onClose, userAuth }) {

    const [errorMsg, setErrorMsg] = useState("");
    const toast = useToast();
    const [documentFile, setDocumentFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };


    const [formData, setFormData] = useState({
        amount_bz: '',
        amount_mx: '',
        status: 1,
        reason: '',
    });

    const handleSubmit = async () => {


        const formDataSend = new FormData();
        formDataSend.append('amount_bz', formData.amount_bz);
        formDataSend.append('amount_mx', formData.amount_mx);
        formDataSend.append('status', formData.status);
        formDataSend.append('reason', formData.reason);


        // Agrega el archivo de documento si existe
        if (documentFile) {
            formDataSend.append('document', documentFile?.file || '');
        }

        try {

            const response = await axios.post('/financialRequest/send', formDataSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response.data.msg) {
                setErrorMsg(response.data.msg);
            } else {
                toast({
                    title: 'Ticket finances send successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
            }
        } catch (error) {
            toast({
                title: "Error Creating a Ticket",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating schedule:', error);
        }
    };

    const removeFile = () => {
        setDocumentFile(null)
        setFormErrors({});
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setDocumentFile({
                file,
                name: file.name,
            });
        }
    };


    return (
        <Flex>
            <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} isCentered closeOnOverlayClick={false}
                closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent borderRadius={'20px'}>
                    <ModalHeader textAlign="center" color={'#808080'} mt={'50px'} fontSize={'40px'}>
                        Financial Request
                    </ModalHeader>
                    <ModalCloseButton onClick={onClose} />
                    <ModalBody>
                        <Flex direction={'column'}>
                            <Flex display='flex' justifyContent="center" mt={'50px'}>

                                <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                    Reason:
                                </Text>
                                <Textarea
                                    bg={'white'}
                                    mr={4}
                                    width={'50%'}
                                    placeholder='Describe the reason for your request'
                                    value={formData.reason}
                                    onChange={handleChange}
                                    name='reason'
                                />

                            </Flex>

                            <Flex display='flex' justifyContent="center" mt={'50px'}>
                                <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                    Total cost:
                                </Text>
                                {[2, 3].includes(userAuth.location_id) ? (
                                    <Input
                                        type='number'
                                        bg={'#f2f2f2'}
                                        width={'250px'}
                                        color={'#808080'}
                                        textAlign={'center'}
                                        value={formData.amount_bz}
                                        onChange={handleChange}
                                        name='amount_bz'
                                        mr={'5%'}
                                    />
                                ) :
                                    <Input
                                        type='number'
                                        bg={'#f2f2f2'}
                                        width={'250px'}
                                        color={'#808080'}
                                        textAlign={'center'}
                                        value={formData.amount_mx}
                                        onChange={handleChange}
                                        name='amount_mx'
                                        mr={'5%'}
                                    />
                                }

                                <Text mt={'5px'} color={'#1c2147'} fontWeight={'bold'} ml={'5%'} mr={'5%'}>
                                    Attachment:
                                </Text>
                                <Input type="file" id="document" style={{ display: 'none' }} onChange={handleDocumentChange}
                                    accept="application/pdf" />
                                <Button as="label" htmlFor="document" cursor="pointer"
                                    bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                    color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    borderRadius={'50%'} height={'50px'}
                                    width={"50px"}
                                >
                                    <ImAttachment ml={2} size={'35px'} />
                                </Button>
                            </Flex>

                            <Flex display={'flex'} justifyContent={'right'}>
                                {documentFile && (
                                    <Flex display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}
                                        mr={'5%'} position={"relative"}
                                        mt={'2%'}
                                    >
                                        <IoDocument ml={2} size={'45px'} color="#cccccc" />
                                        <Text>
                                            {documentFile.name.length > 20
                                                ? `${documentFile.name.substring(0, 20)}…`
                                                : documentFile.name
                                            }
                                        </Text>
                                        <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeFile} _hover={{ background: "tr" }} position={"absolute"} top={-2} right={-2}><CloseIcon boxSize={"15px"} /></Button>
                                    </Flex>
                                )}
                            </Flex>

                            <Flex display={'flex'} justifyContent={'center'} mt={'80px'} mb={'40px'}>
                                <Button
                                    borderRadius={"5px"}
                                    bg={"#b9ce67"}
                                    color={"white"}
                                    _hover={{ background: "#8aad01", color: 'white' }}
                                    onClick={handleSubmit}
                                    mr={'30px'}
                                >
                                    <Text mr={'5px'}>
                                        Send
                                    </Text>
                                    <IoMdSend />
                                </Button>

                                <Button bg={'#424ea8'} _hover={{ background: "#fc7466", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={onClose}>
                                    <CloseIcon mr={2} />  Cancel
                                </Button>
                            </Flex>
                        </Flex>



                    </ModalBody>
                </ModalContent>
            </Modal >
        </Flex>
    );
}

export default FinancialRequest;
