import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input,Flex,
    FormControl, FormErrorMessage, Text, useToast,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'

const UpdateSalaryMX = ({ isOpen, onClose, user_salary }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});

    const clearForm = () => {
        setFormData({
            salary_month: '',
            date_change_salary: ''
        });
        setFormErrors({});
    };

    const [formData, setFormData] = useState({
        user_id: user_salary?.user_id || '',
        salary_month: user_salary?.salary_month || '',
        date_change_salary: user_salary?.date_change_salary || '',
    });

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`/addUserSalaryMX`, formData);
            toast({
                title: 'Salary update successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Updating The Salary",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Updating The Salary:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"8%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Update Salary
                        </Text>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <span style={{ marginLeft: '8px' }}>
                                <span style={{ marginRight: '8px', color: '#7080fc' }}>
                                    {user_salary.name.split(' ')[0]} {user_salary.last_name.split(' ')[0]}
                                </span>
                            </span>
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Flex display={'flex'} direction={'column'}>
                        <Flex direction={'column'} width={'100%'} mr={'1%'}>
                            <Text color={'gray.400'} mb={'1%'}>
                                Salary:
                            </Text>
                            <FormControl isInvalid={!!formErrors.salary_month} >
                                <Input
                                    className='custom-placeholderInputs'
                                    background="white"
                                    type='number'
                                    value={formData.salary_month}
                                    onChange={handleChange}
                                    name='salary_month'
                                />
                                {formErrors.salary_month && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.salary_month}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>
                        <Flex direction={'column'} width={'100%'} mr={'1%'} mt={'3%'}>
                            <Text color={'gray.400'} mb={'1%'}>
                                Date Change:
                            </Text>
                            <FormControl isInvalid={!!formErrors.date_change_salary} >
                                <Input
                                    className='custom-placeholderInputs'
                                    background="white"
                                    type='date'
                                    value={formData.date_change_salary}
                                    onChange={handleChange}
                                    name='date_change_salary'
                                />
                                {formErrors.date_change_salary && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.date_change_salary}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>
                    </Flex>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Update salary
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default UpdateSalaryMX; 