import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Flex, TableContainer
} from '@chakra-ui/react'
import FinancialRequestFilters from './filters';
import FinancialRequest from './table';
import FinancialRequestCreate from './create';
import FinancialRequestUpdate from './update';
import ModalNotesFinancialRequest from './notes';

function UseFinancialRequest() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [userSearch, setUserSearch] = useState(null);
    const [statusSearch, setStatusSearch] = useState(1);
    const [financialRequest, setFinancialRequest] = useState([]);
    const [user, setUser] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [categorySearch, setCategorySearch] = useState(null);
    const [locationSearch, setLocationSearch] = useState('');
    const [paymentSearch, setPaymentSearch] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [filterDateEnd, setFilterDateEnd] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectFinancial, setSelectFinancial] = useState(null);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalCreate, setModalCreate] = useState(false);
    const [modalNotes, setModalNotes] = useState(false);

    const clearFilters = () => {
        setUserSearch(null);
        setCategorySearch(null);
        setLocationSearch('');
        setPaymentSearch('');
        setFilterDate('');
        setFilterDateEnd('');
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }


    const optionsUsers = user.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));

    const optionCategories = categories.map(categories => ({
        value: categories.id,
        label: `${categories.name}`,
    }));


    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/financialRequest?page=${currentPage}`
                + `${userSearch ? `&users=${encodeURIComponent(userSearch)}` : ''}`
                + `${statusSearch ? `&status=${encodeURIComponent(statusSearch)}` : ''}`
                + `${categorySearch ? `&category=${encodeURIComponent(categorySearch)}` : ''}`
                + `${locationSearch ? `&location=${encodeURIComponent(locationSearch)}` : ''}`
                + `${paymentSearch ? `&payment=${encodeURIComponent(paymentSearch)}` : ''}`
                + `${filterDate ? `&startDate=${encodeURIComponent(filterDate)}` : ''}`
                + `${filterDateEnd ? `&endDate=${encodeURIComponent(filterDateEnd)}` : ''}`
            );
            const responseData = response.data;
            setFinancialRequest(responseData.data.data);
            setTotal(responseData.data.total)
            setTotalPages(responseData.data.last_page);
            setUser(responseData.user);
            setCategories(responseData.categories);
        } catch (error) {
            console.error('Error fetching Vacations:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentPage, userSearch, resetKey, statusSearch, categorySearch, locationSearch, paymentSearch, filterDate, filterDateEnd]);


    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            case 'category':
                setCategorySearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleStatusChange = (status) => {
        setStatusSearch(status);
    };

    const handleNotes = (financialRequest) => {
        setModalNotes(true);
        setSelectFinancial(financialRequest);
    }

    const handleUpdate = (financialRequest) => {
        setModalUpdate(true);
        setSelectFinancial(financialRequest);
    }

    const handleCreate = (financialRequest) => {
        setModalCreate(true);
    }

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <FinancialRequestFilters
                    optionsUsers={optionsUsers}
                    userSearch={userSearch}
                    handleSelectChange={handleSelectChange}
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    filterOptions={filterOptions}
                    resetKey={resetKey}
                    clearFilters={clearFilters}
                    handleCreate={handleCreate}
                    categorySearch={categorySearch}
                    setCategorySearch={setCategorySearch}
                    locationSearch={locationSearch}
                    setLocationSearch={setLocationSearch}
                    paymentSearch={paymentSearch}
                    setPaymentSearch={setPaymentSearch}
                    filterDate={filterDate}
                    setFilterDate={setFilterDate}
                    filterDateEnd={filterDateEnd}
                    setFilterDateEnd={setFilterDateEnd}
                    optionCategories={optionCategories}
                />
                <FinancialRequest
                    total={total}
                    currentPage={currentPage}
                    financialRequest={financialRequest}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    loading={loading}
                    handleStatusChange={handleStatusChange}
                    statusSearch={statusSearch}
                    handleUpdate={handleUpdate}
                    handleNotes={handleNotes}
                />
            </Flex >
            {modalCreate && (
                <FinancialRequestCreate
                    isOpen={modalCreate}
                    onClose={() => setModalCreate(false)}
                    optionCategories={optionCategories}
                    filterOptions={filterOptions}
                    data={fetchData}
                />
            )}
            {modalUpdate && (
                <FinancialRequestUpdate
                    isOpen={modalUpdate}
                    onClose={() => setModalUpdate(false)}
                    optionCategories={optionCategories}
                    filterOptions={filterOptions}
                    data={fetchData}
                    financialRequest={selectFinancial}
                />
            )}
            {modalNotes && (
                <ModalNotesFinancialRequest
                    isOpen={modalNotes}
                    onClose={() => setModalNotes(false)}
                    financialRequest={selectFinancial}
                    data={fetchData}
                />
            )}
        </TableContainer >

    );
}
export default UseFinancialRequest; 