import {
    Flex, Button, Text, Input, Box
} from "@chakra-ui/react";
import React from 'react';
import { EditIcon } from '@chakra-ui/icons'
export default function BreaksDetailsSection({ schedule, handleGenerateLunch, handleGenerateBreaks, formDataBreaks, handleBreakInputChange, handleUpdateBreaks }) {

    if (!schedule.breaks || schedule.breaks.length === 0) {
        return (
            <>
                <Flex display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Text color={'#cccccc'} fontWeight={'bold'} mt={"3%"} fontSize={'120%'} textAlign={'center'}>
                        No data
                    </Text>
                    <Button
                        bg={'#5868e0'}
                        borderRadius={'5px'}
                        color={'white'}
                        _hover={{ background: "#808080" }}
                        mt={'5%'}
                        width={'50%'}
                        onClick={handleGenerateLunch}
                    >
                        Generate Lunch
                    </Button>
                    <Button
                        bg={'#5868e0'}
                        borderRadius={'5px'}
                        color={'white'}
                        _hover={{ background: "#808080" }}
                        mt={'5%'}
                        width={'50%'}
                        onClick={handleGenerateBreaks}
                    >
                        Generate Break
                    </Button>
                </Flex>
            </>
        );
    }

    return (
        <Box w={'350px'} h={'570px'} overflowY={"auto"} pr={4}>
            {schedule.breaks.map((breakItem, index) => {
                const auditItem = schedule.audits.find(audit => audit.auditable_id === breakItem.id);
                const shouldDisplayUserInfo = auditItem && breakItem.id_operator !== auditItem.user_id;
                const hasNoLunchBreak = !schedule.breaks.some(breakItem => breakItem.type === 1);

                return (
                    <>
                        <Flex key={breakItem.id} display={'flex'} justifyContent={'center'} mt={'30px'} direction={'column'} bg={'white'} ml={'5%'}>
                            <Flex display={'flex'} justifyContent={'center'} mt={'10px'} mb={'10px'}>
                                <Text mr={'10px'} textAlign={'center'} color={'#808080'} mt={'8px'}>
                                    {breakItem.type === 1 ? 'Lunch:' : 'Break:'}
                                </Text>
                                <Input
                                    type='text'
                                    bg='#f2f2f2'
                                    color={'#808080'}
                                    value={formDataBreaks[index]?.duration || breakItem.duration}
                                    onChange={(e) => handleBreakInputChange(index, e.target.value)}
                                    name='duration'
                                    width={'150px'}
                                    textAlign={'center'}
                                    mr={'5%'}
                                    step="1"
                                />
                                <Button
                                    bg={'#5868e0'}
                                    borderRadius={'5px'}
                                    color={'white'}
                                    _hover={{ background: "#808080" }}
                                    onClick={() => handleUpdateBreaks(breakItem, formDataBreaks[index]?.duration || breakItem.duration)}
                                >
                                    <EditIcon size={'25px'} />
                                </Button>
                            </Flex>

                            {shouldDisplayUserInfo && (
                                <>
                                <Flex display={'flex'} justifyContent={'center'} direction={'column'} textAlign={'center'} mt={'5px'}>
                                        <Text>
                                            Created at:
                                        </Text>
                                        <Text color={'#1c2147'} fontWeight={'bold'}>
                                            {breakItem ? breakItem.formatted_created_at : 'N/A'}
                                        </Text>
                                    </Flex>
                                    <Flex display={'flex'} justifyContent={'center'} direction={'column'} textAlign={'center'} mt={'5px'}>
                                        <Text>
                                            Last update:
                                        </Text>
                                        <Text color={'#1c2147'} fontWeight={'bold'}>
                                            {auditItem ? auditItem.formatted_date : 'N/A'}
                                        </Text>
                                    </Flex>
                                    <Flex display={'flex'} justifyContent={'center'} direction={'column'} textAlign={'center'} mb={'10px'}>
                                        <Text mr={'5px'}>
                                            Updated by:
                                        </Text>
                                        <Text color={'#1c2147'} fontWeight={'bold'}>
                                            {auditItem ? auditItem.user.name : 'N/A'} {auditItem ? auditItem.user.last_name : 'N/A'}
                                        </Text>
                                    </Flex>
                                </>
                            )}
                            {hasNoLunchBreak && (
                                <>
                                    <Flex display={'flex'} justifyContent={'center'} mb={'5%'}>
                                        <Button
                                            bg={'#5868e0'}
                                            borderRadius={'5px'}
                                            color={'white'}
                                            _hover={{ background: "#808080" }}
                                            onClick={handleGenerateLunch}
                                        >
                                            Generate Lunch
                                        </Button>
                                    </Flex>
                                </>
                            )}

                        </Flex>

                    </>
                );
            })}
            <Flex display={'flex'} justifyContent={'center'} mt={'5%'}>
                <Button
                    bg={'#5868e0'}
                    borderRadius={'5px'}
                    color={'white'}
                    _hover={{ background: "#808080" }}
                    onClick={handleGenerateBreaks}
                >
                    Generate Break
                </Button>
            </Flex>
        </Box>

    );
} 