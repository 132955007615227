import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton,
    Box, Flex, Text, Heading, Input, TableContainer, Table, Tr, Th, Td, Tbody, Thead
} from '@chakra-ui/react';

import FormatEndicott from './itemsQA/formatEndicott';
import FormatDefault from './itemsQA/formatDefault';

function ModalQA({ isOpen, onClose, schedule }) {
    const [format, setFormat] = useState([]);
    const [number, setNumber] = useState(''); // Estado para el número de formatos
    const [selectQA, setSelectQA] = useState(null);
    const [callFormats, setCallFormats] = useState([]); // Array para almacenar los formatos de cada llamada
    // Determinar el tipo de componente para cada llamada
    const isEndicott = [19].includes(schedule.id_client); // Condición para usar FormatEndicott
    const [currentCallIndex, setCurrentCallIndex] = useState(0); // Índice de la llamada visible actualmente
    const [loading, setLoading] = useState(false);


    const clientsDefault = [6, 13, 28, 33, 35, 36, 40, 46, 48, 50, 51, 53, 55];
    const isClientDefault = clientsDefault.includes(schedule.id_client);

    const fetchData = async () => {
        setLoading(true);
        try {
            const clientId = isClientDefault ? null : schedule.id_client;
            const response = await axios.get(`/indexQAFormatsClient/${clientId}`);
            const responseData = response.data;
            setFormat(responseData.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleNextCall = () => {
        setCurrentCallIndex((prevIndex) => (prevIndex + 1) % callFormats.length);
    };

    const handlePrevCall = () => {
        setCurrentCallIndex((prevIndex) => (prevIndex - 1 + callFormats.length) % callFormats.length);
    };

    const getRowBackground = (QA) => {
        if (selectQA && selectQA.id === QA.id) {
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };

    const handleNumberChange = (event) => {
        const value = event.target.value;
        if (value === "") {
            setNumber(""); // Permitir que el input esté vacío temporalmente
        } else {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue) && parsedValue > 0) {
                setNumber(parsedValue);

                // Duplicar el estado inicial para cada llamada
                const newCallFormats = Array.from({ length: parsedValue }, () =>
                    format.map(f => ({ ...f }))// Duplicar los datos sin modificar el original
                );
                setCallFormats(newCallFormats);
            }
        }
    };

    // Función para actualizar los datos de una llamada específica
    const updateCallFormat = (index, updatedFormat) => {
        setCallFormats((prev) => {
            const newCallFormats = [...prev];
            newCallFormats[index] = updatedFormat;
            return newCallFormats;
        });
    };


    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'Default'}
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent borderRadius={'20px'} width={"80%"}>
                <ModalCloseButton bg={"#cccccc"} _hover={{ background: '#fc7466' }} />
                <ModalBody display={"flex"} flexDirection={"row"}>
                    <Flex width={"100%"}>
                        <Box
                            bg={"white"}
                            overflowY={'auto'}
                            overflowX={'auto'}
                            width={'100%'}
                        >
                            <Box fontSize={"16px"} color={'#b3b3b3'} pl={'2%'} mb={'2%'} >
                                <Text mt={'40px'} fontSize={"50px"} color={'#1c2147'} fontWeight={"bold"} textAlign={'left'}>
                                    QA
                                </Text>
                                <Flex flexDirection={"column"} color={'#7080fc'} fontSize={'24px'}>
                                    <span style={{ marginRight: '8px', color: '#7080fc', fontWeight: 'bold' }}>
                                        {schedule.operator} {schedule.operatorL}
                                    </span>
                                    <span style={{ marginRight: '8px', color: '#808080', marginTop: '10px', fontStyle: 'italic' }}>
                                        {schedule.date}
                                    </span>
                                </Flex>
                            </Box>

                            {/* Input para el número de formatos */}
                            <Flex pl="2%" mb="4%" alignItems="center">
                                <Text fontSize="lg" mr="4">Calls:</Text>
                                <Input
                                    type="number"
                                    value={number === "" ? "" : number}
                                    onChange={handleNumberChange}
                                    min={1}
                                    width="100px"
                                />
                            </Flex>

                            {/* Mostrar solo el formato actual */}
                            {number && number > 0 && callFormats.length > 0 ? (
                                isEndicott ? (
                                    <FormatEndicott
                                        key={currentCallIndex}
                                        getRowBackground={getRowBackground}
                                        format={callFormats[currentCallIndex]} // Formato específico
                                        number={number}
                                        onNext={handleNextCall} // Botón siguiente
                                        onPrev={handlePrevCall} // Botón anterior
                                        currentIndex={currentCallIndex} // Índice actual
                                        updateFormat={(updatedFormat) => updateCallFormat(currentCallIndex, updatedFormat)} // Actualizar la llamada actual
                                    />
                                ) : (
                                    <FormatDefault
                                        key={currentCallIndex}
                                        getRowBackground={getRowBackground}
                                        format={callFormats[currentCallIndex]} // Formato específico
                                        number={number}
                                        updateFormat={(updatedFormat) => updateCallFormat(currentCallIndex, updatedFormat)} // Actualizar la llamada actual
                                        onNext={handleNextCall} // Botón siguiente
                                        onPrev={handlePrevCall} // Botón anterior
                                        currentIndex={currentCallIndex} // Índice actual
                                    />
                                )
                            ) : (
                                <Text textAlign="center" fontSize="lg" color="gray.500" mt={4} mb={'2%'}>
                                    Please enter a valid number of calls to display.
                                </Text>
                            )}



                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalQA;
