import React, { useState, useEffect } from "react";
import {
    Box, Flex, Button, Text, Heading, TableContainer, Table, Tr, Th, Td, Tbody, Thead
} from '@chakra-ui/react';

function FormatEndicott({ getRowBackground, format, number, updateFormat, onNext, onPrev, currentIndex }) {
    const [totalScore, setTotalScore] = useState(() => calculateTotal(format || []));
    
    // Calcular el total de todas las tablas
function calculateTotal(format) {
    return format
        .map(item => ({
            ...item,
            editableScore: item.editableScore ?? item.score ?? 0, // Valor predeterminado
        }))
        .filter(item => item.selectedOption !== "X" && item.selectedOption !== "N")
        .reduce((acc, item) => {
            const score = parseFloat(item.editableScore || "0");
            return acc + (isNaN(score) ? 0 : score);
        }, 0);
}
// Recalcular el total cuando cambie `format`
useEffect(() => {
    setTotalScore(calculateTotal(format || []));
}, [format]);

// Recalcular al montar el componente
useEffect(() => {
    setTotalScore(calculateTotal(format || []));
}, []); // Este efecto se ejecuta una vez al montar


    // Función para actualizar el valor del puntaje editable
    const handleInputChange = (id, value) => {
        const updatedFormat = format.map((item) =>
            item.id === id ? { ...item, editableScore: value } : item
        );
        updateFormat(updatedFormat); // Actualizar solo esta llamada
    };

    // Función para manejar el cambio en las opciones seleccionadas
    const handleSelectChange = (id, value) => {
        const updatedFormat = format.map((item) =>
            item.id === id
                ? {
                    ...item,
                    selectedOption: value,
                    editableScore: value === "Y" ? item.score : value === "N" ? "0" : value === "X" ? "N/A" : item.score,
                }
                : item
        );
        updateFormat(updatedFormat); // Actualizar solo esta llamada
    };

    return (
        <Flex direction="column" pl={'2%'} pr={'2%'} mb={'2%'}>

            <Flex justifyContent="space-between" alignItems="center" mb={2}>
                <Button onClick={onPrev} colorScheme="blue">
                    Previous
                </Button>
                <Text fontSize="lg" fontWeight="bold" color="#1c2147">
                    {currentIndex + 1} of {number}
                </Text>
                <Button onClick={onNext} colorScheme="blue">
                    Next
                </Button>
            </Flex>

            {/* Mostrar solo el elemento actual basado en Array.from */}
            {Array.from({ length: number }, (_, i) => (
                i === currentIndex && ( // Renderizar solo el índice actual
                    <Box key={i}>
                        <TableContainer>
                            <Table size={'sm'}>
                                <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"} >
                                    {format && format.length > 0 ? (
                                        <>
                                            <Heading as="h2" size="lg" mb={2} textAlign="center">
                                                Call {i + 1}
                                            </Heading>

                                            {/* Sección para las preguntas de tipo 3 (Caller Experience) */}
                                            <Box>
                                                <Heading as="h2" size="md" mb={4}>Caller Experience</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 3).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td
                                                            textAlign={'left'}
                                                            whiteSpace="pre-wrap"
                                                            wordBreak="break-word"
                                                            fontSize={'110%'}
                                                        >
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />

                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            {/* Sección para Soft Skills */}
                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Soft Skills</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 4).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td
                                                            textAlign={'left'}
                                                            whiteSpace="pre-wrap"
                                                            wordBreak="break-word"
                                                            fontSize={'110%'}
                                                        >
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            {/* Sección para Technical Skill */}
                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Technical Skill</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 5).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td textAlign={'left'} whiteSpace="pre-wrap" wordBreak="break-word" fontSize={'110%'}>
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Technical Skill</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 6).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td textAlign={'left'} whiteSpace="pre-wrap" wordBreak="break-word" fontSize={'110%'}>
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Technical Skill</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 7).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td textAlign={'left'} whiteSpace="pre-wrap" wordBreak="break-word" fontSize={'110%'}>
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Technical Skill</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 8).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td
                                                            textAlign={'left'}
                                                            whiteSpace="pre-wrap"
                                                            wordBreak="break-word"
                                                            fontSize={'110%'}
                                                        >
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                        </>
                                    ) : (
                                        <Tr>
                                            <Th colSpan={'9'}>
                                                <Text
                                                    color='#1c2147'
                                                    fontSize={"25px"}
                                                    textAlign={"center"}
                                                    paddingTop={"15px"}
                                                    paddingBottom={"15px"}
                                                    bg={"#f2f2f2"}
                                                    textTransform={'none'}
                                                    fontFamily={'DM Sans'}
                                                    letterSpacing="-1px"
                                                >
                                                    No data
                                                </Text>
                                            </Th>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
            ))}
            {/* Mostrar el Gran Total */}
            <Box mt={8}>
                <Heading as="h2" size="lg" textAlign="center" mb={4}>
                    Pts. Earned
                </Heading>
                <Text fontSize="2xl" textAlign="center" fontWeight="bold" color="#1c2147">
                    {totalScore}
                </Text>
            </Box>
        </Flex>
    );
}

export default FormatEndicott;
