import axios from 'axios';
import React, { useState } from "react";
import {
    Button, FormControl, Flex, useToast, Input, Text, Textarea,
    FormErrorMessage, Modal, ModalBody, ModalOverlay, Heading,
    ModalContent, Alert, AlertIcon
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'

function VacationsRequestModal({ isOpen, onClose, }) {

    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [showAdvice, setShowAdvice] = useState(true);
    const [total, setTotal] = useState('');

    const [formData, setFormData] = useState({
        first_day: '',
        last_day: '',
        comments: '',
    });

    const clearForm = () => {
        setFormData({
            first_day: '',
            last_day: '',
            comments: '',
        });
        setFormErrors({});
    };


    const handleSubmit = async () => {

        const formSubmitData = {
            first_day: formData.first_day,
            last_day: formData.last_day,
            comments: formData.comments,
        };

        try {
            const response = await axios.post('/vacationRequest/create', formSubmitData);

            toast({
                title: 'Request Sent',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            setFormErrors({});
            onClose();
        } catch (error) {
            toast({
                title: "Error Request Sent",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Request Sent:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;

        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'first_day' || name === 'last_day') {
            const updatedFormData = {
                ...formData,
                [name]: value,
            };

            if (updatedFormData.first_day && updatedFormData.last_day) {

                try {
                    const response = await axios.get('/vacationsValidate', {
                        params: {
                            first_day: updatedFormData.first_day,
                            last_day: updatedFormData.last_day,
                        }
                    });

                    const responseData = response.data;
                    setTotal(responseData);

                } catch (error) {
                    console.error('Error validating vacations:', error);
                }
            }
        }
    };


    const showFormButton = () => {
        setShowForm(true);
        setShowAdvice(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'} closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalBody>
                    <Heading style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'5%'} mb={'4%'} fontWeight={'bold'} fontSize={'35px'}>
                        Vacation Request
                    </Heading>
                    <Flex mt={4} ml={10} mr={10} direction={'column'}>
                        {showAdvice && (
                            <Flex direction={'column'} alignItems={'center'} display={'flex'} width={'100%'} mb={'5%'}>
                                <Text fontWeight={'bold'} fontSize={'25px'} mb={'2%'}>
                                    Friendly Reminder:
                                </Text>
                                <Text>
                                    Before you submit your vacation request,
                                    please take a moment to read through the vacation protocol on our DP Portal.
                                </Text>
                                <Text>
                                    This will help make sure everything goes smoothly with your request.
                                    Thanks a bunch for taking the time to do this!
                                </Text>
                                <Flex width={'50%'} justifyContent={'center'} mt={'2%'}>
                                    <Button class="buttonCreate" onClick={showFormButton} >
                                        I've read and i agree
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                        {showForm && (
                            <>
                                <Flex mt={'1%'}>
                                    <Flex direction={'column'} width={'100%'} mr={'1%'}>
                                        <Text color={'gray.400'} mb={'1%'}>
                                            First day of vacation:
                                        </Text>
                                        <FormControl isInvalid={!!formErrors.first_day} w='400px' >
                                            <Input
                                                className='custom-placeholderInputs'
                                                background="white"
                                                type='date'
                                                value={formData.first_day}
                                                onChange={handleChange}
                                                name='first_day'
                                            />
                                            {formErrors.first_day && (
                                                <FormErrorMessage mb={"2%"}>{formErrors.first_day}</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>

                                    <Flex direction={'column'} width={'100%'}>
                                        <Text color={'gray.400'} mb={'1%'}>
                                            Last day of vacation:
                                        </Text>
                                        <FormControl isInvalid={!!formErrors.last_day} w='400px' >
                                            <Input
                                                className='custom-placeholderInputs'
                                                background="white"
                                                type='date'
                                                value={formData.last_day}
                                                onChange={handleChange}
                                                name='last_day'
                                            />
                                            {formErrors.last_day && (
                                                <FormErrorMessage mb={"2%"}>{formErrors.last_day}</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>
                                <Flex mt={'1%'}>
                                    <Flex direction={'column'} width={'95%'}>
                                        <Text color={'gray.400'} mb={'1%'}>
                                            Comments:
                                        </Text>
                                        <Textarea
                                            value={formData.comments}
                                            onChange={handleChange}
                                            bg={'white'}
                                            name='comments' />
                                    </Flex>
                                </Flex>

                                {total > 0 && (
                                    <Flex mt={'3%'} width={'95%'}>
                                        <Alert status='warning'>
                                            <AlertIcon />
                                            This absence overlaps with {total} other people in the company.
                                        </Alert>
                                    </Flex>
                                )}


                                <Flex display={'flex'} justifyContent={'center'} mt={'2%'} mb={'5%'}>
                                    <Button class="buttonCreate">  {/* onClick={handleSubmit} */}
                                        Submit <EditIcon ml={2} />
                                    </Button>
                                    <Button class="buttonCancel" onClick={() => {
                                        onClose();
                                        clearForm();
                                    }}>
                                        Cancel <CloseIcon ml={2} />
                                    </Button>
                                </Flex>
                            </>
                        )}
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default VacationsRequestModal;
