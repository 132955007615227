import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { FaRegCalendar } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { IoTicketSharp } from "react-icons/io5";

import DashboardsDefault from 'views/admin/dashboards/default';
import IncidentsOverview from 'views/admin/main/incidents';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import Default from 'views/admin/main/others/messages';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';
import UsersSchedulesOverview from 'views/admin/main/schedules/weeklyschedule';

import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';


const routesSocialMedia = [
  // --- Dashboards ---
  {
    name: 'Home',
    layout: '/admin',
    path: '/home',
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  //{
    //name: 'Profile',
    //path: '/profile',
    //icon: <Icon as={ImProfile} width="30px" height="30px" color="inherit" />,
    //layout: '/admin',
    //component: <OverviewProfile />,
  //},
  {
    name: 'My schedule',
    layout: '/admin',
    path: '/my-schedule',
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    component: <MyScheduleOverview />,
  },
  {
    name: 'Tickets',
    icon: <Icon as={IoTicketSharp} width="30px" height="30px" color="inherit" />,
    path: '/Ticket',
    collapse: true,
    items: [
      {
        name: 'Ongoing',
        layout: '/admin',
        path: '/ticketU/chat',
        exact: false,
        component: <DefaultUser />,
      },
      {
        name: 'My Completed',
        layout: '/admin',
        path: '/ticketU/finish',
        exact: false,
        component: <IncidentsAgentOverview />,
      },
      
    ],
  },

];

export default routesSocialMedia;
