import React, { useState, useEffect } from 'react';
import {
    Flex,
} from '@chakra-ui/react'
import axios from 'axios';
import PayrollComparativeCOLException from './graphic';
import FiltersCOL from '../filters';

function UseCOLException() {

    const [reportsD, setReportsD] = useState([]);
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);

    const clearFilters = () => {
        setStartDateFilter('');
        setEndDateFilter('');
    }

    const fetchData = async () => {

        try {
            setReportsD([]);
            const response = await axios.get(`/comparative/COLException?` +
                `${startDateFilter ? `&startDate=${encodeURIComponent(startDateFilter)}` : ''}` +
                `${endDateFilter ? `&endDate=${encodeURIComponent(endDateFilter)}` : ''}`
            );
            const responseData = response.data;
            setReportsD(responseData);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [startDateFilter, endDateFilter]);



    return (
        <Flex display={'flex'} direction={'column'}>
            <FiltersCOL
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                clearFilters={clearFilters}
            />
            <PayrollComparativeCOLException
                reportsD={reportsD}
            />
        </Flex>
    );
}
export default UseCOLException; 