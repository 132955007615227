import { Flex, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useState } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons'
import UseCOLException from "./items/COL/Exception/functions";
import UseCOL from "./items/COL/functions";

export default function FinalPayrollCOLOverview() {

  const [showDailyList, setShowDailyList] = useState(false);
  const toggleView = () => {
    setShowDailyList(!showDailyList);
  };
 
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }} >
      <Card px='100px'>
        <Flex align="center" justifyContent='flex-start' mt={4}>
          <Button
            bg={showDailyList ? " #424ea8" : "#fabb1c"}
            color={'white'}
            mr={'2%'}
            borderRadius={'5px'}
            display={'flex'}
            justifyContent={'space-between'}
            onClick={toggleView}
            alignItems={'center'}
            _hover={{ background: "#808080", color: 'white' }}
          >
            {showDailyList ? "Back" : "Colombia Exception"}
            {showDailyList ? <ArrowBackIcon ml={2} /> : ''}
          </Button>
        </Flex>
        {showDailyList ? (
          <UseCOLException />
        ) : (
          <UseCOL />
        )}
      </Card>
    </Flex>
  );
} 