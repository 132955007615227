import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    Flex, TableContainer
} from '@chakra-ui/react'
import VacationsList from '../VacationsTable';
import VacationsFilters from '../filterVacations';
import ModalNotesVacations from '../itemsOngoing/notes';
import VacationCreate from '../itemsOngoing/create';

function UseVacationsTaken({ userData }) {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [userSearch, setUserSearch] = useState(null);
    const [statusSearch, setStatusSearch] = useState('');
    const [vacations, setvacations] = useState([]);
    const [user, setUser] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [modalNotes, setModalNotes] = useState(false);
    const [selectVacation, setSelectVacation] = useState(null);
    const [modalCreate, setModalCreate] = useState(false);

    const clearFilters = () => {
        setUserSearch(null);
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }


    const optionsUsers = user.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));


    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/vacations/taken?page=${currentPage}`
                + `${userSearch ? `&users=${encodeURIComponent(userSearch)}` : ''}`
                + `${statusSearch ? `&status=${encodeURIComponent(statusSearch)}` : ''}`
            );
            const responseData = response.data;
            setvacations(responseData.data.data);
            setTotal(responseData.data.total)
            setTotalPages(responseData.data.last_page);
            setUser(responseData.user);
        } catch (error) {
            console.error('Error fetching Vacations:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentPage, userSearch, resetKey, statusSearch]);


    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const exportVacations = () => {
        axios.get(
            `/vacations/taken/export?
            ${userSearch ? `&users=${userSearch}` : ''}`, {
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Vacations.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error al descargar el archivo Excel:', error);
            });
    };

    const handleStatus = async (vacation, statusField) => {
        try {
            const { isConfirmed, isDenied } = await Swal.fire({
                title: 'What do you want to do with this request?',
                text: "Choose an option:",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Approved',
                denyButtonText: 'Rejected',
                cancelButtonText: 'Cancel',
                showDenyButton: true,
            });

            let statusValue = null;

            if (isConfirmed) {
                statusValue = 2;
            } else if (isDenied) {
                statusValue = 3;
            }

            if (statusValue !== null) {
                const formSubmitData = {
                    model: 'vacations',
                    id: vacation.id,
                    statusField: statusField,
                    statusValue: statusValue
                };

                await axios.post(`/updateStatus`, formSubmitData);
                fetchData();
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleStatusChange = (status) => {
        setStatusSearch(status);
    };

    const handleNotes = (vacation) => {
        setModalNotes(true);
        setSelectVacation(vacation);
    }

    const handleCreate = (vacation) => {
        setModalCreate(true);
    }

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <VacationsFilters
                    optionsUsers={optionsUsers}
                    userSearch={userSearch}
                    handleSelectChange={handleSelectChange}
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    filterOptions={filterOptions}
                    resetKey={resetKey}
                    clearFilters={clearFilters}
                    user={user}
                    exportVacations={exportVacations}
                    handleCreate={handleCreate}
                />
                <VacationsList
                    total={total}
                    currentPage={currentPage}
                    vacations={vacations}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    loading={loading}
                    handleStatus={handleStatus}
                    handleStatusChange={handleStatusChange}
                    statusSearch={statusSearch}
                    user={userData}
                    handleNotes={handleNotes}
                />
            </Flex >
            {modalNotes && (
                <ModalNotesVacations
                    isOpen={modalNotes}
                    onClose={() => setModalNotes(false)}
                    vacation={selectVacation}
                    data={fetchData}
                    userAuth={userData}
                />
            )}
            {modalCreate && (
                <VacationCreate
                    isOpen={modalCreate}
                    onClose={() => setModalCreate(false)}
                    customStyles={customStyles}
                    optionsUsers={optionsUsers}
                    filterOptions={filterOptions}
                    data={fetchData}
                />
            )}
        </TableContainer >

    );
}
export default UseVacationsTaken; 