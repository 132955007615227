import { Flex, Button, Box } from "@chakra-ui/react";
import React, { useState, useEffect } from 'react';
import UseVacationsOngoing from "./items/itemsOngoing/functions";
import UseVacationsApproved from "./items/itemsApproved/functions";
import UseVacationsInProcess from "./items/itemsInProcess/functions";
import UseVacationsTaken from "./items/itemsTaken/functions";
import { useUserData } from 'interceptors/userAuth';


export default function OverviewVacations() {

  const [activeComponent, setActiveComponent] = useState("inProcess"); // Valor inicial por defecto
  const { user, fetchData } = useUserData();

  // Primer useEffect: solo para llamar fetchData una vez al montar el componente.
  useEffect(() => {
    fetchData();
  }, []); // Solo se ejecuta una vez cuando el componente se monta

  // Segundo useEffect: se ejecuta cuando `user` cambia.
  useEffect(() => {
    if (user) {
      if ([1, 2, 3, 4, 5, 6, 7, 8, 9].includes(user.type_user_id)) {
        setActiveComponent("ongoing");
      } else {
        setActiveComponent("inProcess");
      }
    }
  }, [user]);  // Depende de `user`, se ejecuta cuando `user` se actualiza

  const renderComponent = () => {
    switch (activeComponent) {
      case "ongoing":
        return <UseVacationsOngoing userData={user} />;
      case "approved":
        return <UseVacationsApproved userData={user} />;
      case "inProcess":
        return <UseVacationsInProcess userData={user} />;
      case "taken":
        return <UseVacationsTaken userData={user} />;
      default:
        return null;
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Box p={4} mt={'2%'}>
        <Flex gap={4}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(user.type_user_id) ? (
            <Button
              bg={activeComponent === "ongoing" ? "#7080fc" : 'white'}
              color={activeComponent === "ongoing" ? "white" : '#808080'}
              border={activeComponent === "ongoing" ? "" : '2px'}
              borderColor={activeComponent === "ongoing" ? "transparent" : '#808080'}
              onClick={() => setActiveComponent("ongoing")}
              borderRadius={'5px'}
            >
              Ongoing Request
            </Button>
          ) : null}
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(user.type_user_id) ? (
            <Button
              bg={activeComponent === "approved" ? "#7080fc" : "white"}
              color={activeComponent === "approved" ? "white" : '#808080'}
              border={activeComponent === "approved" ? "" : '2px'}
              borderColor={activeComponent === "approved" ? "transparent" : '#808080'}
              onClick={() => setActiveComponent("approved")}
              borderRadius={'5px'}
            >
              Approved Request
            </Button>
          ) : null}
          <Button
            bg={activeComponent === "inProcess" ? "#7080fc" : "white"}
            color={activeComponent === "inProcess" ? "white" : '#808080'}
            border={activeComponent === "inProcess" ? "" : '2px'}
            borderColor={activeComponent === "inProcess" ? "transparent" : '#808080'}
            onClick={() => setActiveComponent("inProcess")}
            borderRadius={'5px'}
          >
            Vacation in Process
          </Button>
          <Button
            bg={activeComponent === "taken" ? "#7080fc" : "white"}
            color={activeComponent === "taken" ? "white" : '#808080'}
            border={activeComponent === "taken" ? "" : '2px'}
            borderColor={activeComponent === "taken" ? "transparent" : '#808080'}
            onClick={() => setActiveComponent("taken")}
            borderRadius={'5px'}
          >
            Vacation Taken
          </Button>
        </Flex>
        <Box >{renderComponent()}</Box>
      </Box>
    </Flex>


  );
};