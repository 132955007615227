import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseSalaryCOL from "./items/COL/functions";

export default function SalariesCOLOverview() {
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card>
        <UseSalaryCOL/>
      </Card>
    </Flex>
  );
} 