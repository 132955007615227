import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Menu, MenuButton, MenuItem, MenuList,
    Button, Text,
} from '@chakra-ui/react'
import { InfoOutlineIcon, EditIcon } from '@chakra-ui/icons'
import { SlOptionsVertical } from "react-icons/sl";
import { FaRegClock, FaUserSlash, FaNoteSticky } from "react-icons/fa6";
import { BiLogoZoom } from "react-icons/bi";
import { MdEditDocument } from 'react-icons/md';
import 'react-datepicker/dist/react-datepicker.css';

function ButtonsDashboard({ schedules, handleInfo, userAuth, userAuthID, handleUpdate, handleNotes, handleCallOut, handleClockOut, handleUpdateZoom, handleRestoreZoom, handleQA }) {

    const allowedUserAuth = [1, 2, 3, 4, 7, 23, 24, 25];
    const isAllowedAuth = allowedUserAuth.includes(userAuth);
    const isZoomValid = schedules.zoom === 0 || schedules.zoom === 1 || schedules.zoom === 2;

    return (

        <>
            <Menu >
                <MenuButton as={Button} _active={{ background: "white", color: '#a7a9ac' }} bg="white" color={"#a7a9ac"} _hover={{ background: "white", color: '#808080' }} borderRadius={'5px'}>
                    <SlOptionsVertical pb={"2px"} />
                </MenuButton>
                <MenuList  >
                    <MenuItem isDisabled  >
                        <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                    </MenuItem>
                    {schedules.option === 4 || schedules.option === 5 || schedules.option === 6 ? (
                        <>
                            <MenuItem>
                                <Button
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    bg="#f2f2f2"
                                    _hover={{ background: "#7080fc", color: 'white' }}
                                    color="#1c2147"
                                    width="100%"
                                    borderRadius={'5px'}
                                    onClick={() => handleInfo(schedules)}
                                >
                                    <Text
                                        flex={1}
                                        textAlign="center"
                                    >
                                        Info
                                    </Text>
                                    <InfoOutlineIcon ml={2} />
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    bg="#f2f2f2"
                                    _hover={{ background: "#ff9fb8", color: 'white' }}
                                    color="#1c2147"
                                    width="100%"
                                    borderRadius={'5px'}
                                    onClick={() => handleNotes(schedules)}
                                >
                                    <Text
                                        flex={1}
                                        textAlign="center"
                                    >
                                        Notes
                                    </Text>
                                    <FaNoteSticky ml={2} />
                                </Button>
                            </MenuItem>
                            {(userAuth === 1 || userAuth === 2 || userAuth === 3 || userAuth === 4 || userAuth === 23 || userAuth === 24 || userAuth === 25 || userAuth === 37 || userAuth === 40 ||
                                (userAuth === 41 && schedules.id_client === 19 && userAuthID !== schedules.id_operator) ||
                                (userAuth === 42 && schedules.id_client === 3 && userAuthID !== schedules.id_operator)) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#8a6dce", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleUpdate(schedules)}
                                    >
                                        <Text
                                            flex={1}
                                            textAlign="center"
                                        >
                                            Edit Schedule
                                        </Text>
                                        <EditIcon ml={2} />
                                    </Button>
                                </MenuItem>
                            ) : null}
                            {(schedules.zoom === null && schedules.option === 2 && schedules.option === 3) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#8a6dce", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleUpdateZoom(schedules)}
                                    >
                                        <Text
                                            flex={1}
                                            textAlign="center"
                                        >
                                            Zoom
                                        </Text>
                                        <BiLogoZoom ml={2} />
                                    </Button>
                                </MenuItem>
                            ) : null}
                            {(isAllowedAuth && isZoomValid) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#3e4a9e", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleRestoreZoom(schedules)}
                                    >
                                        <Text flex={1} textAlign="center">Restored Zoom</Text>
                                        <BiLogoZoom ml={2} />
                                    </Button>
                                </MenuItem>
                            ) : null}

                            {(userAuth === 1) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#3e4a9e", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleQA(schedules)}
                                    >
                                        <Text flex={1} textAlign="center">QA</Text>
                                        <MdEditDocument ml={2} />
                                    </Button>
                                </MenuItem>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <MenuItem>
                                <Button
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    bg="#f2f2f2"
                                    _hover={{ background: "#7080fc", color: 'white' }}
                                    color="#1c2147"
                                    width="100%"
                                    borderRadius={'5px'}
                                    onClick={() => handleInfo(schedules)}
                                >
                                    <Text
                                        flex={1}
                                        textAlign="center"
                                    >
                                        Info
                                    </Text>
                                    <InfoOutlineIcon ml={2} />
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    bg="#f2f2f2"
                                    _hover={{ background: "#ff9fb8", color: 'white' }}
                                    color="#1c2147"
                                    width="100%"
                                    borderRadius={'5px'}
                                    onClick={() => handleNotes(schedules)}
                                >
                                    <Text
                                        flex={1}
                                        textAlign="center"
                                    >
                                        Notes
                                    </Text>
                                    <FaNoteSticky ml={2} />
                                </Button>
                            </MenuItem>
                            {(userAuth === 1 || userAuth === 2 || userAuth === 3 || userAuth === 4 || userAuth === 7 || userAuth === 23 || userAuth === 24 || userAuth === 25 || userAuth === 37 || userAuth === 40 ||
                                (userAuth === 41 && schedules.id_client === 19 && userAuthID !== schedules.id_operator) ||
                                (userAuth === 42 && schedules.id_client === 3 && userAuthID !== schedules.id_operator)) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#8a6dce", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleUpdate(schedules)}
                                    >
                                        <Text
                                            flex={1}
                                            textAlign="center"
                                        >
                                            Edit Schedule
                                        </Text>
                                        <EditIcon ml={2} />
                                    </Button>
                                </MenuItem>
                            ) : null}
                            {(schedules.option === 1) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#fc7466", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleCallOut(schedules)}
                                    >
                                        <Text
                                            flex={1}
                                            textAlign="center"
                                        >
                                            Call out
                                        </Text>
                                        <FaUserSlash />
                                    </Button>
                                </MenuItem>
                            ) : null}
                            {(schedules.option === 2 || schedules.option === 3) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#988d77", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleClockOut(schedules)}
                                    >
                                        <Text
                                            flex={1}
                                            textAlign="center"
                                        >
                                            Clock out
                                        </Text>
                                        <FaRegClock />
                                    </Button>
                                </MenuItem>
                            ) : null}
                            {(schedules.zoom === null && (schedules.option === 2 || schedules.option === 3)) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#3e4a9e", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleUpdateZoom(schedules)}
                                    >
                                        <Text
                                            flex={1}
                                            textAlign="center"
                                        >
                                            Zoom
                                        </Text>
                                        <BiLogoZoom ml={2} />
                                    </Button>
                                </MenuItem>
                            ) : null}
                            {(isAllowedAuth && isZoomValid) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#3e4a9e", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleRestoreZoom(schedules)}
                                    >
                                        <Text flex={1} textAlign="center">Restored Zoom</Text>
                                        <BiLogoZoom ml={2} />
                                    </Button>
                                </MenuItem>
                            ) : null}
                            {(userAuth === 1) ? (
                                <MenuItem>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#3e4a9e", color: 'white' }}
                                        color="#1c2147"
                                        width="100%"
                                        borderRadius={'5px'}
                                        onClick={() => handleQA(schedules)}
                                    >
                                        <Text flex={1} textAlign="center">QA</Text>
                                        <MdEditDocument ml={2} />
                                    </Button>
                                </MenuItem>
                            ) : null}
                        </>
                    )}
                </MenuList>
            </Menu>
        </>
    );
}
export default ButtonsDashboard; 