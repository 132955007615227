import React, { useState } from 'react';
import axios from 'axios';
import Select2 from 'react-select'
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Box, Flex, SimpleGrid, FormControl, FormErrorMessage, useToast, Select, Text
} from '@chakra-ui/react';
import { CloseIcon, AddIcon } from '@chakra-ui/icons'

const VacationCreate = ({ isOpen, onClose, customStyles, optionsUsers, filterOptions, data }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [selectedOperator, setSelectedOperator] = useState([]);
    const [returnDate, setReturnDate] = useState("");
    const [resetKey, setResetKey] = useState(0);
    const [formData, setFormData] = useState({
        first_day: '',
        last_day: '',
        user_id: '',
        status: '',
    });

    const clearForm = () => {
        setFormData({
            first_day: '',
            last_day: '',
            user_id: '',
            status: '',
        });
        setFormErrors({});
        setResetKey(resetKey + 1);
        setSelectedOperator([]);
    };

    const handleSubmit = async () => {

        const selectAgent = selectedOperator.value;

        const formSubmitData = {
            first_day: formData.first_day,
            last_day: formData.last_day,
            status: formData.status,
            user_id: selectAgent,
        };

        try {
            const response = await axios.post('/vacation/create', formSubmitData);

            toast({
                title: 'Vacation register success',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            data();
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating a vacation",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating vacation:', error);
            setFormErrors(error.response.data.errors);
        }
    };
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "last_day") {
            const lastDayDate = new Date(value);
            if (!isNaN(lastDayDate)) {
                const nextDay = new Date(lastDayDate);
                nextDay.setDate(nextDay.getDate() + 1);
                setReturnDate(nextDay.toISOString().split("T")[0]); // Formato YYYY-MM-DD
            } else {
                setReturnDate("");
            }
        }
    };
    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Create Vacation Register
                        </Text>

                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl> Select Agent
                                <Select2
                                    options={optionsUsers}
                                    styles={customStyles}
                                    value={selectedOperator}
                                    onChange={(selectedOption) => setSelectedOperator(selectedOption)}
                                    key={`userSelect-${resetKey}`}
                                    isSearchable
                                    filterOption={filterOptions}
                                />

                            </FormControl>
                            <FormControl isInvalid={!!formErrors.first_day}>Start Date
                                <Input
                                    type="date"
                                    name='first_day'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.first_day}
                                    onChange={handleChange}
                                />
                                {formErrors.first_day && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.last_day}>End Date
                                <Input
                                    type="date"
                                    name='last_day'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.last_day}
                                    onChange={handleChange}
                                />
                                {formErrors.last_day && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl>
                                Return Date
                                <Input
                                    type="date"
                                    className="custom-placeholderInputs"
                                    background="white"
                                    value={returnDate} // El valor viene del estado `returnDate`
                                    isReadOnly // Desactiva la edición del input
                                />
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.status}>
                                <Select
                                    w='460px'
                                    fontSize={'md'}
                                    borderRadius={'10px'}
                                    name="status"
                                    textAlign={"left"}
                                    background="white"
                                    value={formData.status}
                                    onChange={handleChange}
                                    h='44px'
                                    maxh='44px'
                                    errorBorderColor='#fc7466'
                                >
                                    <option value=''>Select status</option>
                                    <option value='1'>In review</option>
                                    <option value='4'>Only Payment</option>
                                </Select>
                                {formErrors.status && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Create <AddIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default VacationCreate;
