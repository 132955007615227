import React from "react";
import {
    Flex, Table, Button, Box, Thead, Tr, Badge, Td, Tbody, ButtonGroup, Th, Text
} from '@chakra-ui/react';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";

function RequestTable({ setCurrentPage, currentPage, requests, getColorStatus, total, totalPages }) {

    return (
        <>
            <Table size={'sm'} mt={"2%"}>
                <Thead bg={"white"}>
                    <Tr>
                        <Th textAlign={'center'}>Requests</Th>
                        <Th textAlign={'center'}>Start Date</Th>
                        <Th textAlign={'center'}>End Date</Th>
                        <Th textAlign={'center'}>Status</Th>
                    </Tr>
                </Thead>
                <Tbody fontWeight={"bold"}>
                    {requests && requests.length > 0 ? (
                        requests.map((requests, index) => (
                            <Tr key={requests.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                                <Td textAlign={'center'} color={"#1b2559"} >
                                    {requests.Requests}
                                </Td>
                                <Td textAlign={'center'} color={"#1b2559"} >
                                    {requests.start_date}
                                </Td>
                                <Td textAlign={'center'} color={"#1b2559"} >
                                    {requests.end_date}
                                </Td>

                                <Td textAlign={'center'}>
                                    <Badge
                                        color='white'
                                        bg={getColorStatus(requests.status_MG)}
                                        borderRadius={'5px'}
                                        width={'100%'}
                                        fontSize={'10px'}
                                        whiteSpace={'pre-wrap'}
                                    >
                                        {(() => {
                                            switch (requests.status_MG) {
                                                case 1:
                                                    return "In Review";
                                                case 2:
                                                    return "Approved";
                                                case 3:
                                                    return "Denied";
                                                case 4:
                                                    return "N/A";
                                                default:
                                                    return "---";
                                            }
                                        })()}
                                    </Badge>
                                </Td>


                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Th colSpan={'12'} >
                                <Text
                                    color='#1b2559'
                                    fontSize={"25px"}
                                    textAlign={"center"}
                                    paddingTop={"15px"}
                                    paddingBottom={"15px"}
                                    bg={"#f2f2f2"}
                                    textTransform={'none'}
                                    fontFamily={'DM Sans'}
                                    letterSpacing="-1px"
                                >
                                    No data
                                </Text>
                            </Th>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <GrStatusInfo color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No request
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <GrStatusInfo color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'Request' : 'Requests'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"15%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </>
    );
}

export default RequestTable;