import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Flex, Text, Input } from "@chakra-ui/react";
import { MdEditDocument } from "react-icons/md";

function QALayouts({ navigate }) {

    const [clientS, setClientS] = useState([]);
    const [allData, setAllData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const boxData = [
        { label: "Format 5 in 1", component: "UseQA5_1" },
    ];

    const fetchDataClient = async () => {
        try {
            const response = await axios.get(`/settings/options`);
            const responseData = response.data;
            setClientS(responseData.client);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    useEffect(() => {
        fetchDataClient();
    }, []);

    useEffect(() => {
        if (clientS.length > 0) {
            const additionalData = clientS.map(client => ({
                label: `Format ${client.name}`,
                component: "UseQAClient",
                client: client.id
            }));

            const combinedData = [...boxData, ...additionalData];
            setAllData(combinedData);
            setFilteredData(combinedData); // Inicializa la lista filtrada
        } else {
            setAllData(boxData);
            setFilteredData(boxData);
        }
    }, [clientS]);

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = allData.filter(item =>
            item.label.toLowerCase().includes(value)
        );

        setFilteredData(filtered);
    };

    return (
        <Flex direction="column" mt={"2%"} width={"100%"} align="center">
            <Input
                placeholder="Search format..."
                value={searchTerm}
                onChange={handleSearch}
                mb={"2%"}
                width="80%"
                borderRadius="5px"
                bg="white"
                color="black"
                _placeholder={{ color: "gray.500" }}
            />

            {filteredData.map((item, index) => (
                <Box
                    key={index}
                    border={"1px"}
                    mb={"2%"}
                    p={"4"}
                    display="flex"
                    borderRadius={"5px"}
                    justifyContent="space-between"
                    alignItems="center"
                    bg={"#424ea8"}
                    color="white"
                    width="80%"
                >
                    <Text
                        fontSize={"110%"}
                        cursor="pointer"
                        _hover={{ textDecoration: "underline" }}
                        onClick={() => navigate(item.component, item.client)}
                    >
                        {item.label}
                    </Text>
                    <MdEditDocument size={"1.5rem"} />
                </Box>
            ))}
        </Flex>
    );
}

export default QALayouts;
