import React, { useEffect } from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td,
    Button, ButtonGroup,
    Text, Flex, Box, Badge,
    Menu, MenuItem, MenuList, MenuButton
} from '@chakra-ui/react'
import { SettingsIcon, CheckIcon, EditIcon } from '@chakra-ui/icons'
import { FaMoneyBillWave } from "react-icons/fa";
import { BsArrowLeftShort, BsArrowRightShort, BsClipboardCheckFill } from "react-icons/bs";
import { FaNoteSticky } from "react-icons/fa6";

function FinancialRequest({ user, total, currentPage, financialRequest, setCurrentPage, totalPages, handleStatusChange, statusSearch, handleNotes, handleUpdate }) {

    const getColorStatus = (option) => {
        const colorMap = {
            1: '#fabb1c',
            2: '#a2bd34',
            3: '#fc7466'
        };
        return colorMap[option] || '#8a6dce';
    };

    return (
        <>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2}>
                                    <FaMoneyBillWave color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No Request
                                </Text>


                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <FaMoneyBillWave color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                                    {total === 1 ? 'Financial Request' : 'Financial Requests'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={'2%'} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
            <Table size={'sm'} mt={"2%"}>
                <Thead bg={"white"}>
                    <Tr>
                        <Th textAlign={'center'}>Employee</Th>
                        <Th textAlign={'center'}>Category</Th>
                        <Th textAlign={'center'}>Location</Th>
                        <Th textAlign={'center'}>Amount MX</Th>
                        <Th textAlign={'center'}>Amount BZ</Th>
                        <Th textAlign={'center'}>Amount US</Th>
                        <Th textAlign={'center'}>Payment Method</Th>
                        <Th textAlign={'center'}>Date Request</Th>
                        <Th textAlign={'center'}>Status</Th>
                        <Th textAlign={'center'}>Notes</Th>
                        <Th textAlign={'center'}>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {financialRequest && financialRequest.length > 0 ? (
                        financialRequest.map((financialRequest, index) => (
                            <Tr key={financialRequest.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {financialRequest.user.name.split(' ')[0]} {financialRequest.user.last_name.split(' ')[0]}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    <Badge bg={'#424ea8'} color={"white"} borderRadius={'5px'}>
                                        {financialRequest.categories ? financialRequest.categories.name : '----'}
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    <Badge bg={'#424ea8'} width={"50px"} color={"white"} borderRadius={'5px'}>
                                        {(() => {
                                            switch (financialRequest.location_id) {
                                                case 1:
                                                    return "MX";
                                                case 2:
                                                    return "BZ";
                                                case 3:
                                                    return "BZ";
                                                case 4:
                                                    return "RD";
                                                case 5:
                                                    return "COL";
                                                case 6:
                                                    return "GTM";
                                                default:
                                                    return "MX";
                                            }
                                        })()}
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {financialRequest.amount_mx ? financialRequest.amount_mx : '----'}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {financialRequest.amount_bz ? financialRequest.amount_bz : '----'}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {financialRequest.amount_us ? financialRequest.amount_us : '----'}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    <Badge
                                        color='white'
                                        bg={getColorStatus(financialRequest.payment_method)}
                                        borderRadius={'5px'}
                                        width={'75px'}
                                        fontSize={'10px'}
                                        whiteSpace={'pre-wrap'}
                                    >
                                        {(() => {
                                            switch (financialRequest.payment_method) {
                                                case 1:
                                                    return "Effective";
                                                case 2:
                                                    return "Transfer";
                                                default:
                                                    return "---";
                                            }
                                        })()}
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {financialRequest.date_formatted}
                                </Td>
                                <Td textAlign={'center'} fontWeight={"bold"}>
                                    <Badge
                                        color='white'
                                        bg={getColorStatus(financialRequest.status)}
                                        borderRadius={'5px'}
                                        width={'75px'}
                                        fontSize={'10px'}
                                        whiteSpace={'pre-wrap'}
                                    >
                                        {(() => {
                                            switch (financialRequest.status) {
                                                case 1:
                                                    return "In Review";
                                                case 2:
                                                    return "Approved";
                                                case 3:
                                                    return "Denied";
                                                default:
                                                    return "---";
                                            }
                                        })()}
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    <Flex justifyContent={'center'}>
                                        <Button
                                            borderRadius={'5px'}
                                            color={'white'}
                                            bg={'#1c2147'}
                                            _hover={{ background: "#808080", }}
                                            onClick={() => handleNotes(financialRequest)}
                                        >
                                            {(financialRequest.countNotes !== 0) ? (
                                                <>
                                                    <Box>
                                                        <FaNoteSticky />
                                                    </Box>
                                                    <Text ml={'5%'}>
                                                        {financialRequest.countNotes}
                                                    </Text>
                                                </>
                                            ) :
                                                <Box>
                                                    <FaNoteSticky />
                                                </Box>
                                            }
                                        </Button>
                                    </Flex>
                                </Td>
                                <Td textAlign={'center'}>
                                    <Menu>
                                        <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                                            <SettingsIcon pb={"2px"} />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem isDisabled>
                                                <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                                            </MenuItem>
                                            <MenuItem
                                                display={"flex"}
                                                justifyContent={"center"}
                                                flexDirection={"column"}
                                                alignItems="center">
                                                <Button
                                                    borderRadius={"5px"}
                                                    bg={"#f2f2f2"}
                                                    color={"#1c2147"}
                                                    _hover={{ background: "#ff6f94", color: 'white' }}
                                                    onClick={() => handleUpdate(financialRequest)}
                                                    width={"100%"}
                                                    justifyContent="space-between"
                                                    alignItems="center">
                                                    <Text flex={1} textAlign="center">
                                                        Review
                                                    </Text>
                                                    <BsClipboardCheckFill ml={2} verticalAlign="middle" />
                                                </Button>
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>

                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Th colSpan={'15'} >
                                <Text
                                    color='#1c2147'
                                    fontSize={"25px"}
                                    textAlign={"center"}
                                    paddingTop={"15px"}
                                    paddingBottom={"15px"}
                                    bg={"#f2f2f2"}
                                    textTransform={'none'}
                                    fontFamily={'DM Sans'}
                                    letterSpacing="-1px"
                                >
                                    No data
                                </Text>
                            </Th>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <ButtonGroup
                    isAttached
                    variant="outline"
                    justifyContent={"center"}
                    display={"flex"}
                    width={"max-content"}
                >
                    <Button
                        borderRadius={"0px"}
                        bg={statusSearch === 1 ? "#7080fc" : "white"}
                        color={statusSearch === 1 ? "white" : '#808080'}
                        border={statusSearch === 1 ? "" : '2px'}
                        borderColor={statusSearch === 1 ? "transparent" : '#808080'}
                        onClick={() => handleStatusChange(1)}
                        _hover={{
                            background: "#808080",
                            color: "white"
                        }}
                    >
                        New Request
                    </Button>

                    <Button
                        borderRadius={"0px"}
                        ml={4}
                        bg={statusSearch === 2 ? "#7080fc" : "white"}
                        color={statusSearch === 2 ? "white" : '#808080'}
                        border={statusSearch === 2 ? "" : '2px'}
                        borderColor={statusSearch === 2 ? "transparent" : '#808080'}
                        onClick={() => handleStatusChange(2)}
                        _hover={{
                            background: "#808080",
                            color: "white"
                        }}
                    >
                        Approved
                    </Button>

                    <Button
                        borderRadius={"0px"}
                        ml={4}
                        bg={statusSearch === 3 ? "#7080fc" : "white"}
                        color={statusSearch === 3 ? "white" : '#808080'}
                        border={statusSearch === 3 ? "" : '2px'}
                        borderColor={statusSearch === 3 ? "transparent" : '#808080'}
                        onClick={() => handleStatusChange(3)}
                        _hover={{
                            background: "#808080",
                            color: "white"
                        }}
                    >
                        Denied
                    </Button>
                </ButtonGroup>
            </Box>
        </>

    );
}
export default FinancialRequest; 