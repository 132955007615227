import React from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Badge, Text, Button, ButtonGroup,
    Flex, Box,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { FaUserGroup } from "react-icons/fa6";
import CategoriesFilters from './filters';
import UpdateCategorie from './update';
import CategorieButtons from './Buttons'; 
import CreateCategorie from './create';

function CategoriesTable({setSearchType, openModalCreate, searchType, searchTerm, setSearchTerm, handleSearch, clearFilters, categorie, getRowBackground, handleUpdate, loading, handleDelete, total, setCurrentPage, currentPage, totalPages, modalCreateIsOpen, closeModalCreate, fetchData, ModalUpdateIsOpen, closeModalUpdate, selectCategorie, setFlashing }) {

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    direction='column'
                    w='100%'
                    p={3}
                    borderRadius="16px"
                >
                    <Flex align="center" justifyContent="flex-end" >
                        <Button class="buttonCreate" onClick={openModalCreate}>
                            Add Categorie <AddIcon ml={2} />
                        </Button>
                    </Flex>
                </Flex>
                 <CategoriesFilters
                    setSearchType={setSearchType}
                    searchType={searchType}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleSearch={handleSearch}
                    clearFilters={clearFilters}
                    Text={Text}
                /> 
                <br />
                <Table size={'sm'}>
                    <Thead bg={"white"}>
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>ID</Th>
                            <Th color='#808080' textAlign={'center'}>Categorie</Th>
                            <Th color='#808080' textAlign={'center'}>Status</Th>
                            <Th textAlign={'center'}>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {categorie && categorie.length > 0 ? (
                            categorie.map(categorie => (
                                <Tr key={categorie.id} bg={getRowBackground(categorie)}>
                                    <Td textAlign={'center'}>{categorie.id}</Td>
                                    <Td textAlign={'center'}>{categorie.name}</Td>
                                    <Td textAlign={'center'}>
                                        <Badge borderRadius={"5px"} color={"white"} bg={categorie.status === 1 ? '#8a6dce' : '#ff9fb8'} width={'75px'}>
                                            {categorie.status === 1 ? 'Active' : 'Inactive'}
                                        </Badge>
                                    </Td>
                                    <Td textAlign={'center'}>
                                        <CategorieButtons
                                        handleUpdate={handleUpdate}
                                        categorie={categorie}
                                        handleDelete={handleDelete}
                                        loading={loading}
                                        /> 
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Th colSpan={'9'} >
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        No data
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <FaUserGroup color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No categories
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <FaUserGroup color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'Categorie' : 'Categories'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
            <CreateCategorie isOpen={modalCreateIsOpen} onClose={closeModalCreate} onCategorieCreated={fetchData} />
             {ModalUpdateIsOpen && (
                <UpdateCategorie isOpen={ModalUpdateIsOpen} onClose={closeModalUpdate} categorie={selectCategorie} onCategorieUpdated={fetchData} setFlashing={setFlashing} />)}
         </TableContainer>
    );
}
export default CategoriesTable;
