import React, { useState, useEffect } from "react";
import {
    Button, Flex, Text, Input
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import { AiOutlineClear } from "react-icons/ai";

function FiltersRD({ startDateFilter, setStartDateFilter, endDateFilter, setEndDateFilter, clearFilters }) {

    const [displayDateRange, setDisplayDateRange] = useState("");

    const formatDate = (dateString) => {
        if (!dateString) return "";

        // Descomponer el formato "YYYY-MM-DD"
        const [year, month, day] = dateString.split("-").map(Number);
        const date = new Date(year, month - 1, day); // Crear fecha localmente

        const dayOfMonth = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const yearValue = date.getFullYear();

        // Agregar sufijo para el día
        const suffix =
            dayOfMonth % 10 === 1 && dayOfMonth !== 11
                ? "st"
                : dayOfMonth % 10 === 2 && dayOfMonth !== 12
                    ? "nd"
                    : dayOfMonth % 10 === 3 && dayOfMonth !== 13
                        ? "rd"
                        : "th";

        return `${dayOfMonth}${suffix} ${monthName}, ${yearValue}`;
    };



    useEffect(() => {
        const calculateDefaultDateRange = () => {
            const today = new Date();
            const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
            const lastSaturday = new Date(lastSunday);
            lastSaturday.setDate(lastSaturday.getDate() - 1);
            lastSunday.setDate(lastSunday.getDate() - 7);

            return {
                start: lastSunday.toISOString().split("T")[0], // Convertir a formato YYYY-MM-DD
                end: lastSaturday.toISOString().split("T")[0],
            };
        };

        const updateDateRangeText = () => {
            const start = startDateFilter || calculateDefaultDateRange().start;
            const end = endDateFilter || calculateDefaultDateRange().end;
            setDisplayDateRange(`Date range: ${formatDate(start)} to ${formatDate(end)}`);
        };

        updateDateRangeText();
    }, [startDateFilter, endDateFilter]);

    return (
        <>
            <Flex mt={'3%'} mb={'5%'}>
                <Flex
                    direction='row'
                    w='max-content'
                    bg="gray.200"
                    p={2}
                    borderRadius="16px"
                >
                    <Flex align="center">
                        <Flex
                            border={'1px solid #808080'}
                            display={'flex'}
                            borderRadius={'4px'}
                            ml={4} mr={4}
                        >
                            <Input
                                width={'100%'}
                                border={'0px'}
                                type="date"
                                fontSize={"sm"}
                                value={startDateFilter}
                                onChange={(e) => {
                                    setStartDateFilter(e.target.value);
                                }}
                                _focusVisible={'none'}
                                color={'#808080'}
                            />
                            <Button bg={'none'} color={'#808080'} onClick={() => {
                                setStartDateFilter('');
                            }}>
                                <RepeatIcon />
                            </Button>
                        </Flex>
                        <Flex
                            border={'1px solid #808080'}
                            display={'flex'}
                            borderRadius={'4px'}
                            mr={4}
                        >
                            <Input
                                width={'100%'}
                                border={'0px'}
                                type="date"
                                fontSize={"sm"}
                                value={endDateFilter}
                                onChange={(e) => {
                                    setEndDateFilter(e.target.value);
                                }}
                                _focusVisible={'none'}
                                color={'#808080'}
                            />
                            <Button bg={'none'} color={'#808080'} onClick={() => {
                                setEndDateFilter('');
                            }}>
                                <RepeatIcon />
                            </Button>
                        </Flex>
                        <Button bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" onClick={clearFilters}>
                            <AiOutlineClear size={'26px'} />
                        </Button>
                    </Flex>
                </Flex>

                <Flex height={'max-content'} display={'flex'} justifyContent={'center'} mt={'0.5%'} ml={'5%'}>
                    <Text fontSize={'150%'} fontWeight={'bold'} color={'#424ea8'}>
                        {displayDateRange}
                    </Text>
                </Flex>
            </Flex>
        </>
    );
}
export default FiltersRD; 