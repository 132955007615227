import axios from 'axios';
import React from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody,
    Button, Flex, Image, Text
} from '@chakra-ui/react';
import survey from 'assets/img/home/Atsi Season HIST.jpg';

function ModalSurvey({ isOpen, onClose }) {

    const startSurvey = async () => {
        try {
            const response = await axios.post('/confirmSurvey');

           onClose();

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'} closeOnOverlayClick={false} closeOnEsc={false} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody marginTop={'4%'} marginBottom={'4%'}>
                    <Flex display={'flex'} justifyContent={'left'} position={'relative'}>
                        <Image
                            src={survey}
                            height={'50%'}
                            width={'50%'}
                        />
                        <Flex direction={'column'} p={'5%'}>
                            <Text mt={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                Great news—our ATSI award season has officially kicked off!
                                Here’s a quick rundown of what it’s all about and what to expect.
                            </Text>
                            <Text mt={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                The ATSI Award of Excellence program is designed as a tool of measurement of our customer service levels.
                                The goal here is to raise the bar for quality across our industry and deliver the best possible caller experience.
                                If you have any questions, feel free to reach out to your supervisors, trainers, or the QA team.
                            </Text>
                            <Text mt={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                We’re excited to see you all shine this season—let’s make it a great one!
                            </Text>

                            <Button
                                mt={'5%'}
                                size={'md'}
                                color={'white'}
                                bg={'#424ea8'}
                                borderRadius={'5px'}
                                _hover={{ background: '#808080', color: 'white' }}
                                onClick={startSurvey}
                            >
                                I've read and i agree
                            </Button>
                        </Flex>


                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalSurvey;
