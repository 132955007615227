import React from 'react';
import {
    Flex, Text
} from '@chakra-ui/react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList
} from 'recharts';

const formatCurrency = (value) => {
    return `$${Number(value).toLocaleString()}`;
};
function PayrollComparativeBZ({ reportsD }) {
    const data = reportsD.map((report, index) => ({
        name: `Belize`,
        projected: Number(report.total_payment_after_retention_proyected),
        final: Number(report.total_payment_final),
    }));

    const totalUsers = reportsD.reduce((sum, report) => sum + report.Total_users, 0);

    return (
        <Flex direction="column" w="100%" align="center">
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                        formatter={(value) => [formatCurrency(value), "Amount"]}
                    />
                    <Legend />
                    <Bar dataKey="projected" fill="#8884d8" name="Projected Payment">
                        <LabelList dataKey="projected" position="top" formatter={(value) => formatCurrency(value)} />
                    </Bar>
                    <Bar dataKey="final" fill="#82ca9d" name="Final Payment">
                        <LabelList dataKey="final" position="top" formatter={(value) => formatCurrency(value)} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <Text fontSize="lg" mt={4}>
                Employees: {totalUsers}
            </Text>
        </Flex>
    );
}

export default PayrollComparativeBZ;
