import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import {
    useToast
} from '@chakra-ui/react'
import axios from 'axios';
import ClientsTable from './table';


function UseClients() {
    const [client, setClient] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('name');
    const [selectClient, setSelectClient] = useState(null);
    const [flashing, setFlashing] = useState(false);

    const clearFilters = () => {
        setSearchTerm('');
        setSearchType('');
        setCurrentPage(1);
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`/clients?page=${currentPage}&${searchType}=${searchTerm}`);
            const responseData = response.data;
            setClient(responseData.data);
            setTotalPages(responseData.last_page);
            setTotal(responseData.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, searchTerm, searchType, flashing]);

    const handleSearch = () => {
        setCurrentPage(1);
    };

    const openModalCreate = () => {
        setModalCreateIsOpen(true);
    }

    const closeModalCreate = () => {
        setModalCreateIsOpen(false);
    }

    const handleUpdate = async (client) => {
        setSelectClient(client);
        setModalUpdateIsOpen(true);
        try {
            const response = await axios.get(`/clients/edit/${client.id}`);

        } catch (error) {
            console.error('Error fetching client details:', error);
        }
    };
    const closeModalUpdate = () => {
        setModalUpdateIsOpen(false);
    }
    const handleDelete = async (client) => {
        const msg = client.status == 0 ? 'activate' : 'deactivate';
        const confirmDelete = await Swal.fire({
            title: `Do you want to ${msg} this client?`,
            text: `The client will be ${msg}...`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: `Yes, ${msg} it!`

        }).then((result) => {
            return result.isConfirmed
        });
        if (confirmDelete) {
            setLoading(true);
            setSelectClient(client);
            try {
                const response = await axios.post(`/clients/status/${client.id}`);
                toast({
                    title: "Status Update",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setFlashing(client);
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Updating The Status Item",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const getRowBackground = (client) => {
        if (selectClient && selectClient.id === client.id) {
            if (flashing) {
                return '#fcd677';
            }
            return client.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return client.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };
    return (
        <ClientsTable
        setSearchType={setSearchType}
        openModalCreate={openModalCreate}
        searchType={searchType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        clearFilters={clearFilters}
        client={client}
        getRowBackground={getRowBackground}
        handleUpdate={handleUpdate}
        loading={loading}
        handleDelete={handleDelete}
        total={total}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        modalCreateIsOpen={modalCreateIsOpen}
        closeModalCreate={closeModalCreate}
        fetchData={fetchData}
        ModalUpdateIsOpen={ModalUpdateIsOpen}
        closeModalUpdate={closeModalUpdate}
        selectClient={selectClient}
        setFlashing={setFlashing} />
    );
}
export default UseClients;