import React, { useState, useEffect } from 'react';
import { Input, Select, Button,
    Flex, Box,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons'
import { AiOutlineClear } from "react-icons/ai";
import 'jspdf-autotable';
const CategoriesFilters = ({ setSearchType, searchType, searchTerm, setSearchTerm, handleSearch, clearFilters }) => {

    return(
        <Flex
            direction='column'
            w='100%'
            bg="gray.200"
            p={2}
            borderRadius="16px"
        >
            <Flex align="center">
                <Select
                    borderRadius={"5px"}
                    value={searchType}
                    mr={4}
                    width={'120px'}
                    borderColor={'#808080'}
                    color={'#808080'}
                    onChange={(e) => setSearchType(e.target.value)}
                    _focusVisible={'none'}
                    _hover={{
                        borderColor: "#808080",
                    }}
                >
                    <option value="name">Name</option>
                </Select>
                <Flex mr={4}
                    border={'1px solid #808080'}
                    display={'flex'}
                    borderRadius={'4px'}
                >
                    <Input
                        borderRadius={"5px"}
                        width={'100%'}
                        border={'0px'}
                        type="text"
                        placeholder={`Search for ${searchType === 'name' ? 'Name' : 'ID'}`}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        _placeholder={{
                            color: '#808080',
                        }}
                        _focusVisible={'none'}
                    />



                    <Button bg={'none'} color={'#808080'} onClick={handleSearch}>
                        <SearchIcon />
                    </Button>
                </Flex>
                <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                    <AiOutlineClear size={'26px'} />
                </Button>
            </Flex>
        </Flex>
    );
};
export default CategoriesFilters;