import axios from 'axios';
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import {
    Modal, ModalOverlay, ModalContent, Input, ModalBody, ModalCloseButton,
    Box, Flex,
    Text,
    Button,
    Textarea, Select, useToast, FormControl, FormErrorMessage, VStack
} from '@chakra-ui/react';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons'
import { IoDocument } from "react-icons/io5";
import bgMundochat from "assets/img/dashboards/Mundo_chat_2.png";
import { ImAttachment } from "react-icons/im";


function ModalNotesFinancialRequest({ isOpen, onClose, financialRequest, data }) {

    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [buttonText, setButtonText] = useState("Add");
    const [notes, setNotes] = useState([]);
    const [hoveredMessage, setHoveredMessage] = useState(null);
    const [documentFile, setDocumentFile] = useState(null);
    const [formData, setFormData] = useState({
        description: '',
    });

    const clearForm = () => {
        setFormData({
            description: '',
        });
    };

    const fetchData = async (schedules) => {
        try {
            const response = await axios.get(`/showNotes/financial/${financialRequest.id}`);
            setNotes(response.data);
        } catch (error) {
            console.error('Error fetching Notes:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setButtonText("Submitting...");

        const formDataSend = new FormData();
        formDataSend.append('description', formData.description);
        formDataSend.append('id_bill', financialRequest.id);

        if (documentFile) {
            formDataSend.append('document', documentFile?.file || '');
        }

        try {
            const response = await axios.post('/storeNotes/financial', formDataSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            toast({
                title: 'Note created successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            fetchData();
            data();
            setFormErrors({});
            setButtonText("Add");
        } catch (error) {
            toast({
                title: "Error Creating a note",
                status: "error",
                duration: 1000,
                isClosable: true,
            });
            console.error('Error creating employee:', error);
            setFormErrors(error.response.data.errors);
            setButtonText("Add");
        } finally {
            setIsSubmitting(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const downloadFile = async (note) => {
        try {
            const response = await axios.get(`/documents/financial/${encodeURIComponent(note.path)}`, { responseType: 'blob' });

            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
            const suggestedFileName = fileNameMatch ? fileNameMatch[1] : note.path;

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', suggestedFileName);
            document.body.appendChild(link);
            link.click();

            // Liberar recursos
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };


    const removeFile = () => {
        setDocumentFile(null)
        setFormErrors({});
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setDocumentFile({
                file,
                name: file.name,
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'} isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent borderRadius={'20px'} width={"75%"} bg={"#f2f2f2"}>

                <ModalCloseButton bg={"#cccccc"} _hover={{ background: '#fc7466' }} />
                <ModalBody display={"flex"} flexDirection={"row"}>
                    <Flex width={"100%"} justify={"space-evenly"} mt={"8%"}>

                        <Box borderRadius={"30px"} bg={"white"}
                            width={"600px"}
                            height={"800px"}
                            overflow='hidden'
                            px={{ base: "10px", md: "40px" }}
                            position='static'
                            style={{
                                overflowY: 'auto',
                                backgroundImage: `url(${bgMundochat})`,
                                backgroundSize: 'auto',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <Text mt={'40px'} fontSize={"50px"} color={'#1c2147'} fontWeight={"bold"} textAlign={'left'}>
                                Notes & Attachments
                            </Text>

                            <Flex direction={'column'} height={"560px"} overflowY={"auto"}>
                                {notes && notes.length > 0 ? (
                                    notes.map((note, index) => (
                                        <Flex p={1}
                                            key={note.id}
                                            boxSizing='border-box'
                                            direction={'column'}
                                        >
                                            <VStack spacing={3}>
                                                <Flex flexDirection={"column"} width={"100%"}  >
                                                    <Box width={"100%"} mt={2} mb={4} height={"4px"} bg={"#e6e6e6"}></Box>
                                                    <Flex mt={'1%'} >
                                                        <Box textAlign={'center'} color={'#b3b3b3'} textColor={"#b3b3b3"} fontWeight='medium' bg={"white"} borderRadius={"10px"} ml={4} >
                                                            <Text
                                                                width={"380px"}
                                                                textAlign={"left"}
                                                                fontSize={'14px'}
                                                                p={2} textColor={"black"}
                                                                whiteSpace={'pre-wrap'}
                                                            >
                                                                {note.description}
                                                            </Text>
                                                            <Flex display={'flex'}>
                                                                {note.path && (
                                                                    <Button
                                                                        onClick={() => downloadFile(note)}
                                                                        borderRadius={'5px'}
                                                                        size={'lg'}
                                                                        width={'80px'}
                                                                        height={'80px'}
                                                                        mr={'20px'}
                                                                        mt={'5%'} mb={'5%'}
                                                                        onMouseEnter={() => setHoveredMessage(note.id)}
                                                                        onMouseLeave={() => setHoveredMessage(null)}
                                                                        _hover={{ background: "#cccccc" }}
                                                                        bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                                                    >
                                                                        {hoveredMessage === note.id ? (
                                                                            <DownloadIcon fontSize={'35px'} color="white" />
                                                                        ) : (
                                                                            <IoDocument ml={2} size={'50px'} color="white" />
                                                                        )}
                                                                    </Button>
                                                                )}

                                                            </Flex>
                                                        </Box>
                                                    </Flex>
                                                    <Flex ml={12} mt={2} justifyContent={'left'}>
                                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                                            {note.user_id === 1 ? 'Dedicated portal system' : `${note.user.name} ${note.user.last_name}`}
                                                        </Text>
                                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                                            {note.created}
                                                        </Text>
                                                        <span style={{ color: '#808080', fontSize: '12px' }}></span>
                                                    </Flex>
                                                </Flex>


                                            </VStack>
                                            <span style={{ borderTop: '4px', borderColor: '#808080', width: '100%' }}></span>
                                        </Flex>
                                    ))
                                ) : (
                                    <Box>
                                        <Text mt={"50%"} bg={"#f2f2f2"} fontSize={"24px"} fontWeight={"bold"} textAlign={"center"}>
                                            No notes
                                        </Text>
                                    </Box>
                                )}
                            </Flex>
                        </Box>
                        <Flex direction={'column'}>
                            <Box mr={"5px"} width={"550px"} height={"max-content"} p={10} borderRadius={"30px"} bg={"white"} >

                                <FormControl mt={4}>
                                    Attachment
                                    <Input type="file" id="document" style={{ display: 'none' }} onChange={handleDocumentChange}
                                        accept="application/pdf" />
                                    <Button as="label" htmlFor="document" cursor="pointer"
                                        bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                        color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                        borderRadius={'5%'}
                                        width={"52%"}
                                        ml='5%'
                                    >
                                        <ImAttachment ml={2} size={'25px'} />
                                    </Button>
                                </FormControl>

                                <Flex display={'flex'} justifyContent={'left'}>
                                    {documentFile && (
                                        <Flex display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}
                                            mr={'5%'} position={"relative"}
                                            mt={'2%'}
                                        >
                                            <IoDocument ml={2} size={'45px'} color="#cccccc" />
                                            <Text>
                                                {documentFile.name.length > 20
                                                    ? `${documentFile.name.substring(0, 20)}…`
                                                    : documentFile.name
                                                }
                                            </Text>
                                            <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeFile} _hover={{ background: "tr" }} position={"absolute"} top={-2} right={-2}><CloseIcon boxSize={"15px"} /></Button>
                                        </Flex>
                                    )}
                                </Flex>

                                <FormControl isInvalid={!!formErrors.description}>
                                    <Textarea borderColor={"#b3b3b3"}
                                        placeholder="Add a description here..."
                                        mt={'40px'}
                                        name="description"
                                        minHeight={"200px"}
                                        maxHeight={"510px"}
                                        className='custom-placeholderInputs'
                                        background="white"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        errorBorderColor='#fc7466'
                                    >
                                    </Textarea>
                                    {formErrors.description && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>

                                <Box mt={'10%'} display={'flex'} alignContent={"center"} justifyContent={"center"}>
                                    <Button
                                        bg={'#5868e0'}
                                        _hover={{ background: "#8aad01", color: 'white' }}
                                        width={"120px"}
                                        color={'white'}
                                        mr={3}
                                        borderRadius='8px'
                                        fontWeight='bold'
                                        onClick={handleSubmit}
                                        isDisabled={isSubmitting}
                                    >
                                        <Text mr={1}>
                                            {buttonText}
                                        </Text>
                                    </Button>
                                    <Button bg="#fc7466" color={'white'} _hover={{ background: "#808080", color: 'white' }} width={"120px"} mr={3} borderRadius='8px' fontWeight='bold' onClick={onClose}>

                                        <Text mr={1}>
                                            Exit notes
                                        </Text>
                                    </Button>
                                </Box>

                            </Box>
                        </Flex>


                    </Flex>
                </ModalBody >

            </ModalContent >
        </Modal >
    );
}



export default ModalNotesFinancialRequest;
