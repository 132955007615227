import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    Flex, TableContainer
} from '@chakra-ui/react'
import VacationsFilters from '../filterVacations';
import VacationsOngoing from './table';
import ModalNotesVacations from './notes';
import VacationCreate from './create';
import VacationsUpdate from './update';


function UseVacationsOngoing({ userData }) {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [userSearch, setUserSearch] = useState(null);
    const [statusSearch, setStatusSearch] = useState(1);
    const [vacations, setvacations] = useState([]);
    const [user, setUser] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [modalNotes, setModalNotes] = useState(false);
    const [selectVacation, setSelectVacation] = useState(null);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalCreate, setModalCreate] = useState(false);

    const clearFilters = () => {
        setUserSearch(null);
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }


    const optionsUsers = user.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));


    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/vacations/ongoing?page=${currentPage}`
                + `${userSearch ? `&users=${encodeURIComponent(userSearch)}` : ''}`
                + `${statusSearch ? `&status=${encodeURIComponent(statusSearch)}` : ''}`
            );
            const responseData = response.data;
            setvacations(responseData.data.data);
            setTotal(responseData.data.total)
            setTotalPages(responseData.data.last_page);
            setUser(responseData.user);
        } catch (error) {
            console.error('Error fetching Vacations:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentPage, userSearch, resetKey, statusSearch]);


    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const exportVacations = () => {
        axios.get(
            `/vacations/ongoing/export?
            ${userSearch ? `&users=${userSearch}` : ''}`, {
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Vacations.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error al descargar el archivo Excel:', error);
            });
    };

    const handleStatus = async (vacations, statusField) => {
        try {
            // Definir diferentes opciones según el valor de statusField y el estado de permission.status_Client
            const inputOptions = getInputOptions(statusField, vacations);

            // Construir los elementos HTML para los radio buttons y el input text
            const radioOptionsHTML = Object.keys(inputOptions).map(key => `
                <div style="margin-top: 3%; margin-bottom: 5%; display: inline-block;">
                    <input type="radio" id="radio-${key}" name="statusRadio" value="${key}">
                    <label for="radio-${key}">${inputOptions[key]}</label>
                </div>
            `).join('');

            // Agregar el campo de texto
            const customHTML = `
                <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px; text-align: center;">
                    <p><strong>Name:</strong> ${vacations.user.name.split(' ')[0]} ${vacations.user.last_name.split(' ')[0]}</p>
                    <p><strong>Start Date:</strong> ${vacations.start_date}</p>
                    <p><strong>End Date:</strong> ${vacations.end_date}</p>
                    <p><strong>Return date:</strong> ${vacations.return_date}</p>
                </div>
                ${radioOptionsHTML}
                    <textarea id="commentText" placeholder="Add a note" rows="4" style="width: 100%; border: 1px solid;"></textarea>
            `;

            const { isConfirmed, value } = await Swal.fire({
                title: 'What do you want to do with this permission?',
                html: customHTML,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                preConfirm: () => {
                    const selectedRadio = Swal.getPopup().querySelector('input[name="statusRadio"]:checked');
                    const commentText = Swal.getPopup().querySelector('#commentText').value;

                    if (!selectedRadio) {
                        Swal.showValidationMessage('You need to choose an option!');
                        return false;
                    }

                    // if (!commentText) {
                    //     Swal.showValidationMessage('The note is required');
                    //     return false;
                    // }

                    return {
                        statusValue: parseInt(selectedRadio.value, 10),
                        description: commentText
                    };
                },
                customClass: {
                    popup: 'custom-swal-popup' // Clase CSS personalizada
                }
            });

            if (isConfirmed) {
                const { statusValue, description } = value;

                const formSubmitData = {
                    model: 'vacations',
                    id: vacations.id,
                    statusField: statusField,
                    statusValue: statusValue,
                    description: description // Incluir el comentario en los datos a enviar
                };

                await axios.post(`/updateStatus`, formSubmitData);
                fetchData();
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };


    const styles = `
    .custom-swal-popup {
        width: auto !important; /* Aumentar el ancho */
        height: auto; /* Aumentar la altura si es necesario */
        font-size: 18px; /* Ajustar el tamaño de la fuente */
    }
`;

    // Añadir el CSS personalizado al documento
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    // Función para definir las opciones según statusField y status_Client
    const getInputOptions = (statusField, vacations) => {
        switch (statusField) {
            case 'status_HR':
                return {
                    2: 'Approved',
                    3: 'Denied',
                };
            case 'status_Client':
                return {
                    2: 'Approved',
                    3: 'Denied',
                };
            case 'status_MG':
                return {
                    2: 'Approved',
                    3: 'Denied'
                };
        }
    };

    const handleStatusChange = (status) => {
        setStatusSearch(status);
    };

    const handleNotes = (vacation) => {
        setModalNotes(true);
        setSelectVacation(vacation);
    }

    const handleUpdate = (vacation) => {
        setModalUpdate(true);
        setSelectVacation(vacation);
    }

    const handleCreate = (vacation) => {
        setModalCreate(true);
    }

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <VacationsFilters
                    optionsUsers={optionsUsers}
                    userSearch={userSearch}
                    handleSelectChange={handleSelectChange}
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    filterOptions={filterOptions}
                    resetKey={resetKey}
                    clearFilters={clearFilters}
                    user={user}
                    exportVacations={exportVacations}
                    handleCreate={handleCreate}
                />
                <VacationsOngoing
                    total={total}
                    currentPage={currentPage}
                    vacations={vacations}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    loading={loading}
                    handleStatus={handleStatus}
                    handleStatusChange={handleStatusChange}
                    statusSearch={statusSearch}
                    user={userData}
                    handleNotes={handleNotes}
                    handleUpdate={handleUpdate}
                />
            </Flex >
            {modalNotes && (
                <ModalNotesVacations
                    isOpen={modalNotes}
                    onClose={() => setModalNotes(false)}
                    vacation={selectVacation}
                    data={fetchData}
                    userAuth={userData}
                />
            )}
            {modalCreate && (
                <VacationCreate
                    isOpen={modalCreate}
                    onClose={() => setModalCreate(false)}
                    customStyles={customStyles}
                    optionsUsers={optionsUsers}
                    filterOptions={filterOptions}
                    data={fetchData}
                />
            )}
            {modalUpdate && (
                <VacationsUpdate
                    isOpen={modalUpdate}
                    onClose={() => setModalUpdate(false)}
                    data={fetchData}
                    selectV={selectVacation}
                />
            )}
        </TableContainer >

    );
}
export default UseVacationsOngoing; 