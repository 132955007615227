import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Text, Box, Flex, SimpleGrid, FormControl, FormErrorMessage, useToast,
} from '@chakra-ui/react';
import { CloseIcon, EditIcon } from '@chakra-ui/icons'

const VacationsUpdate = ({ isOpen, onClose, data, selectV }) => {

    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        first_day: selectV?.start_date || '',
        last_day: selectV?.end_date || '',
    });

    console.log(selectV);
    const handleSubmit = async () => {

        const formSubmitData = {
            first_day: formData.first_day,
            last_day: formData.last_day,
        };

        try {
            const response = await axios.post(`/vacation/update/${selectV.id}`, formSubmitData);

            toast({
                title: 'Medical Note Updated',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            data();
            onClose();
        } catch (error) {
            toast({
                title: "Error Creating a Medical Note",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating medical note:', error);
            setFormErrors(error.response.data.errors);
        }
    };
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Edit Vacation Register
                        </Text>

                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.first_day}>Start Date
                                <Input
                                    type="date"
                                    name='first_day'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.first_day}
                                    onChange={handleChange}
                                />
                                {formErrors.first_day && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.last_day}>End Date
                                <Input
                                    type="date"
                                    name='last_day'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.last_day}
                                    onChange={handleChange}
                                />
                                {formErrors.last_day && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Update<EditIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default VacationsUpdate;
