import {
    Table, Thead, Tbody, Tr, Th, Td,
    Button, ButtonGroup, useColorModeValue,
    Text, Flex, Box, Badge, Input,
} from '@chakra-ui/react'
import React, { useEffect, useState } from "react";
import axios from 'axios';
import CardProfile from 'components/card/cardProfile';
import UseRequestInReview from './requests/inreview/functions';
import UseRequestApproved from './requests/approved/functions';
import UseRequestDenied from './requests/denied/functions';


export default function UserRequests(props) {

    const { ...rest } = props;
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const [activeComponent, setActiveComponent] = useState("inReview"); // Valor inicial por defecto

    const renderComponent = () => {
        switch (activeComponent) {
            case "inReview":
                return <UseRequestInReview />;
            case "approved":
                return <UseRequestApproved />;
            case "denied":
                return <UseRequestDenied />;
            default:
                return null;
        }
    };

    return (
        <CardProfile  {...rest} >
            <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                mt='10px'
                mb='4px'
                ml={'2%'}
            >
                Status Requests
            </Text>
            <Box p={4} mt={'2%'}>
                <Flex gap={4}>
                    <Button
                        bg={activeComponent === "inReview" ? "#7080fc" : 'white'}
                        color={activeComponent === "inReview" ? "white" : '#808080'}
                        border={activeComponent === "inReview" ? "" : '2px'}
                        borderColor={activeComponent === "inReview" ? "transparent" : '#808080'}
                        onClick={() => setActiveComponent("inReview")}
                        borderRadius={'5px'}
                    >
                        In Review
                    </Button>
                    <Button
                        bg={activeComponent === "approved" ? "#7080fc" : "white"}
                        color={activeComponent === "approved" ? "white" : '#808080'}
                        border={activeComponent === "approved" ? "" : '2px'}
                        borderColor={activeComponent === "approved" ? "transparent" : '#808080'}
                        onClick={() => setActiveComponent("approved")}
                        borderRadius={'5px'}
                    >
                        Approved
                    </Button>
                    <Button
                        bg={activeComponent === "denied" ? "#7080fc" : "white"}
                        color={activeComponent === "denied" ? "white" : '#808080'}
                        border={activeComponent === "denied" ? "" : '2px'}
                        borderColor={activeComponent === "denied" ? "transparent" : '#808080'}
                        onClick={() => setActiveComponent("denied")}
                        borderRadius={'5px'}
                    >
                        Denied
                    </Button>
                </Flex>
                <Box >{renderComponent()}</Box>
            </Box>

        </CardProfile>
    );
}
