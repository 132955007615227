import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Flex
} from '@chakra-ui/react'
import RequestTable from '../table';
import RequestFilters from '../filters';


function UseRequestDenied() {

    const [requests, setRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [filterDate, setFilterDate] = useState('');
    const [filterDateEnd, setFilterDateEnd] = useState('');

    const clearFilters = () => {
        setFilterDate('');
        setFilterDateEnd('');
        setCurrentPage(1);
    }

    const fechDataRequests = async () => {

        try {
            const response = await axios.get(
                `/viewRequest/denied?page=${currentPage}`
                + `${filterDate ? `&startDate=${filterDate.trim()}` : ''}`
                + `${filterDateEnd ? `&endDate=${filterDateEnd.trim()}` : ''}`
            );

            const responseData = response.data;
            setRequests(responseData.data);
            setTotalPages(responseData.last_page);
            setTotal(responseData.total)

        } catch (error) {

            console.error('Error al obtener datos del usuario:', error);

        }
    };

    useEffect(() => {
        fechDataRequests();
    }, [currentPage, filterDate, filterDateEnd]);

    const getColorStatus = (option) => {
        const colorMap = {
          1: '#fabb1c',
          2: '#a2bd34',
          3: '#fc7466'
        };
        return colorMap[option] || '#8a6dce';
      };


    return (
        <Flex
            direction='column'
            w='100%'
        >
            <RequestFilters
                filterDate={filterDate}
                setFilterDate={setFilterDate}
                setCurrentPage={setCurrentPage}
                filterDateEnd={filterDateEnd}
                setFilterDateEnd={setFilterDateEnd}
                clearFilters={clearFilters}
            />
            <RequestTable
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                requests={requests}
                getColorStatus={getColorStatus}
                total={total}
                totalPages={totalPages}
            />
        </Flex >
    );
}
export default UseRequestDenied; 