import React, { useEffect } from 'react';
import {
  Table, Thead, Tbody, Tr, Th, Td,
  Button, ButtonGroup,
  Text, Flex, Box, Badge,
  Menu, MenuItem, MenuList, MenuButton
} from '@chakra-ui/react'
import { SettingsIcon, CheckIcon, EditIcon } from '@chakra-ui/icons'
import { BsAirplaneFill } from "react-icons/bs";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { FaNoteSticky } from "react-icons/fa6";

function VacationsOngoing({ user, total, currentPage, vacations, setCurrentPage, totalPages, handleStatus, handleStatusChange, statusSearch, handleNotes, handleUpdate }) {

  const getColorStatus = (option) => {
    const colorMap = {
      1: '#fabb1c',
      2: '#a2bd34',
      3: '#fc7466'
    };
    return colorMap[option] || '#8a6dce';
  };

  return (
    <>
      <Box width={"100%"} display={"flex"} mt={"30px"}>
        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
          {total === 0 ? (
            <>
              <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                <Box mt={'5px'} mr={2}>
                  <BsAirplaneFill color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                  No Vacations
                </Text>


              </Flex>
            </>
          ) : (
            <>
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  {total}
                </Text>
                <Box mt={'5px'} mr={1}>
                  <BsAirplaneFill color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                  {total === 1 ? 'Vacation' : 'Vacations'}
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        <ButtonGroup isAttached variant="outline" ml={'2%'} justifyContent={'center'} display={'flex'} width={"max-content"} >
          <Button
            borderRadius={'0px'}
            onClick={() => setCurrentPage(currentPage - 1)}
            isDisabled={currentPage === 1}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowLeftShort fontSize="20px" />
          </Button>
          <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
          <Button
            borderRadius={'0px'} ml={4}
            onClick={() => setCurrentPage(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowRightShort fontSize="20px" />
          </Button>
        </ButtonGroup>
      </Box>
      <Table size={'sm'} mt={"2%"}>
        <Thead bg={"white"}>
          <Tr>
            <Th textAlign={'center'}>ID</Th>
            <Th textAlign={'center'}>Name</Th>
            <Th textAlign={'center'}>Email</Th>
            <Th textAlign={'center'}>Start Date</Th>
            <Th textAlign={'center'}>End Date</Th>
            <Th textAlign={'center'}>Return Date</Th>
            <Th textAlign={'center'}>Deducted Days</Th>
            <Th textAlign={'center'}>Pending Days</Th>
            <Th textAlign={'center'}>Period</Th>
            <Th textAlign={'center'}>Anniversary</Th>
            <Th textAlign={'center'}>Notes</Th>
            <Th textAlign={'center'}>HR Approval</Th>
            <Th textAlign={'center'}>Client/Managment Approval</Th>
            <Th textAlign={'center'}>Status</Th>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(user.type_user_id) ? (
              <Th textAlign={'center'}>Actions</Th>
            ) : null}
          </Tr>
        </Thead>
        <Tbody>
          {vacations && vacations.length > 0 ? (
            vacations.map((vacations, index) => (
              <Tr key={vacations.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {vacations.user.profile.mat_dp}
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {vacations.user.name.split(' ')[0]} {vacations.user.last_name.split(' ')[0]}
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {vacations.email}
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {vacations.start_date_formatted}
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {vacations.end_date_formatted}
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {vacations.return_date}
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {vacations.deducted}d
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {vacations.pending_days}d
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  <Badge color={"white"} bg={'#fca066'} borderRadius={'5px'} width={'60px'} fontSize={'10px'} whiteSpace={'pre-wrap'}>
                    {(() => {
                      switch (vacations.period) {
                        case 1:
                          return "First Period";
                        case 2:
                          return "Second Period";
                        default:
                          return "---";
                      }
                    })()}
                  </Badge>
                </Td>

                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {vacations.anniversary}
                </Td>

                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  <Flex justifyContent={'center'}>
                    <Button
                      borderRadius={'5px'}
                      color={'white'}
                      bg={'#1c2147'}
                      _hover={{ background: "#808080", }}
                      onClick={() => handleNotes(vacations)}
                    >
                      {(vacations.countNotes !== 0) ? (
                        <>
                          <Box>
                            <FaNoteSticky />
                          </Box>
                          <Text ml={'5%'}>
                            {vacations.countNotes}
                          </Text>
                        </>
                      ) :
                        <Box>
                          <FaNoteSticky />
                        </Box>
                      }
                    </Button>
                  </Flex>
                </Td>
                <Td textAlign={'center'} fontWeight={"bold"}>
                  <Badge
                    color='white'
                    bg={getColorStatus(vacations.status_HR)}
                    borderRadius={'5px'}
                    width={'75px'}
                    fontSize={'10px'}
                    whiteSpace={'pre-wrap'}
                  >

                    {(() => {
                      switch (vacations.status_HR) {
                        case 1:
                          return "In Review";
                        case 2:
                          return "Approved";
                        case 3:
                          return "Denied";
                        default:
                          return "---";
                      }
                    })()}
                  </Badge>

                </Td>
                <Td textAlign={'center'} fontWeight={"bold"}>
                  <Badge
                    color='white'
                    bg={getColorStatus(vacations.status_Client)}
                    borderRadius={'5px'}
                    width={'75px'}
                    fontSize={'10px'}
                    whiteSpace={'pre-wrap'}
                  >

                    {(() => {
                      switch (vacations.status_Client) {
                        case 1:
                          return "In Review";
                        case 2:
                          return "Approved";
                        case 3:
                          return "Denied";
                        case 4:
                          return "N/A";
                        default:
                          return "---";
                      }
                    })()}
                  </Badge>

                </Td>
                <Td textAlign={'center'} fontWeight={"bold"}>
                  <Badge
                    color='white'
                    bg={getColorStatus(vacations.status_MG)}
                    borderRadius={'5px'}
                    width={'75px'}
                    fontSize={'10px'}
                    whiteSpace={'pre-wrap'}
                  >

                    {(() => {
                      switch (vacations.status_MG) {
                        case 1:
                          return "In Review";
                        case 2:
                          return "Approved";
                        case 3:
                          return "Denied";
                        case 4:
                          return "Only Payment";
                        default:
                          return "---";
                      }
                    })()}
                  </Badge>

                </Td>


                {[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(user.type_user_id) ? (
                  <Td textAlign={'center'}>
                    <Menu>
                      <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                        <SettingsIcon pb={"2px"} />
                      </MenuButton>
                      <MenuList>
                        <MenuItem isDisabled>
                          <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                        </MenuItem>
                        <MenuItem
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems="center">
                          <Button
                            borderRadius={"5px"}
                            bg={"#f2f2f2"}
                            color={"#1c2147"}
                            _hover={{ background: "#ff6f94", color: 'white' }}
                            onClick={() => handleUpdate(vacations)}
                            width={"100%"}
                            justifyContent="space-between"
                            alignItems="center">
                            <Text flex={1} textAlign="center">
                              Edit Register
                            </Text>
                            <EditIcon ml={2} verticalAlign="middle" />
                          </Button>
                        </MenuItem>
                        <MenuItem
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems="center">
                          <Button
                            borderRadius={"5px"}
                            bg={"#f2f2f2"}
                            color={"#1c2147"}
                            _hover={{ background: "#7080fc", color: 'white' }}
                            onClick={() => handleStatus(vacations, 'status_HR')}
                            width={"100%"}
                            justifyContent="space-between"
                            alignItems="center">
                            <Text flex={1} textAlign="center">
                              HR Approval
                            </Text>
                            <CheckIcon ml={2} verticalAlign="middle" />
                          </Button>
                        </MenuItem>
                        <MenuItem
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems="center">
                          <Button
                            borderRadius={"5px"}
                            bg={"#f2f2f2"}
                            color='#1c2147'
                            _hover={{ background: "#8a6dce", color: 'white' }}
                            onClick={() => handleStatus(vacations, 'status_Client')}
                            width={"100%"}
                            justifyContent="space-between"
                            alignItems="center">
                            <Text flex={1} textAlign="center">
                              Client/Manager Approval
                            </Text>
                            <CheckIcon ml={2} verticalAlign="middle" />
                          </Button>
                        </MenuItem>
                        <MenuItem
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems="center">
                          <Button
                            borderRadius={"5px"}
                            bg={"#f2f2f2"}
                            color='#1c2147'
                            _hover={{ background: "#fabb1c", color: 'white' }}
                            onClick={() => handleStatus(vacations, 'status_MG')}
                            width={"100%"}
                            justifyContent="space-between"
                            alignItems="center">
                            <Text flex={1} textAlign="center">
                              Status
                            </Text>
                            <CheckIcon ml={2} verticalAlign="middle" />
                          </Button>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                ) : null}
              </Tr>
            ))
          ) : (
            <Tr>
              <Th colSpan={'15'} >
                <Text
                  color='#1c2147'
                  fontSize={"25px"}
                  textAlign={"center"}
                  paddingTop={"15px"}
                  paddingBottom={"15px"}
                  bg={"#f2f2f2"}
                  textTransform={'none'}
                  fontFamily={'DM Sans'}
                  letterSpacing="-1px"
                >
                  No data
                </Text>
              </Th>
            </Tr>
          )}
        </Tbody>
      </Table>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(user.type_user_id) ? (
        <Box width={"100%"} display={"flex"} mt={"30px"}>
          <ButtonGroup
            isAttached
            variant="outline"
            justifyContent={"center"}
            display={"flex"}
            width={"max-content"}
          >
            {/* Botón para New Request */}
            <Button
              borderRadius={"0px"}
              bg={statusSearch === 1 ? "#7080fc" : "white"}
              color={statusSearch === 1 ? "white" : '#808080'}
              border={statusSearch === 1 ? "" : '2px'}
              borderColor={statusSearch === 1 ? "transparent" : '#808080'}
              onClick={() => handleStatusChange(1)}
              _hover={{
                background: "#808080",
                color: "white"
              }}
            >
              New Request
            </Button>

            {/* Botón para Client / Manager Approval */}
            <Button
              borderRadius={"0px"}
              ml={4}
              bg={statusSearch === 2 ? "#7080fc" : "white"}
              color={statusSearch === 2 ? "white" : '#808080'}
              border={statusSearch === 2 ? "" : '2px'}
              borderColor={statusSearch === 2 ? "transparent" : '#808080'}
              onClick={() => handleStatusChange(2)}
              _hover={{
                background: "#808080",
                color: "white"
              }}
            >
              Client / Manager Approval
            </Button>
          </ButtonGroup>
        </Box>
      ) : null}
    </>

  );
}
export default VacationsOngoing; 